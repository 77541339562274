.EmbedProperties {
    label {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        margin: 0px !important;
        border: 1px solid #cacaca !important;
        background: #efefef !important;
        font-size: 0.8em;
        font-weight: 400 !important;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        text-align: right !important;
        line-height: 31px !important;
        padding-right: 6px;
        width: 135px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }

    input{
        width: 250px;
    }
}

.label-pec {
    height: 33px;
}

.reverse {
    div > .r{
        flex-direction: row-reverse !important;
        align-items: center;
    }
}
