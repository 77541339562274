.JobRequirements {
    .department-name {
        text-align: right;
        margin-top: 20px;
        color: #666;
        font-style: italic;

        .fas {
            padding-right: 12px;
            padding-left: 12px;
        }
    }
    .contractor-chip {
        margin-right: 12px;
        background-color: rgb(130, 130, 130);
        padding: 10px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        color: white;
    }
    .p-tabview-selected .contractor-chip {
        background-color: transparent;
        color: #666;
    }

    .light .p-tabview-nav-link {
        font-size: 0.8rem;
    }
    .light .p-tabview-nav-link .p-chip {
        margin-right: 12px;
    }
    .light .p-chip-text {
        line-height: 1;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    .light .p-tabview-panels, .light .p-tabview-selected a {
        background-color: #f7f7f7 !important;
    }

    .light .p-tabview-selected a {

    }

    .light .p-tabview-panels .p-tabview-panels, .light .p-tabview-panels .p-tabview-selected a {
        background-color: #ededed !important;
    }
}
