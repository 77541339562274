.AdminWorkRequirements {

    .legend-container {
        flex: 1;
        height: 100%;
        padding: 10px;

        .p-editor-container {
            height: 80%;
        }
    }

    .parent-legend-container {
        display: flex;
        flex-direction: row;
        height: 100% !important;
        width: 100%;

        >div {
            height: 100%;
        }
    }

    .code {
        max-width: 100%;
        overflow: auto;
        word-wrap: break-word;
    }

    .order-handle {
        width: 100%;
        min-width: 3px;
        height: 100%;
        color: #ddd;
    }

    .fas {
        color: #666;
    }

    tr.selected td {
        background-color: rgb(255, 255, 198);
    }

    .inner-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: calc(100% - 16px);

        .requirement-group-btn button {
            border: 1px solid rgb(121, 121, 121);
            border-color: rgb(240, 179, 179) !important;
            background: rgb(236, 200, 195) !important;
        }

        .requirement-group-btn button:hover {
            background: rgb(218, 164, 155) !important;
            border: 1px solid rgb(241, 172, 164) !important;
        }

        .toolbar {
            display: flex;
            flex-direction: row;
            height: 38px;
            align-items: center;
            background-color: #e7e7e7;
            position: sticky;
            top: 38px;
            z-index: 1000;
        }

        .body {
            height: 100%;
            flex: 1;
        }

        .hide {
            display: none;
        }

        .color-circle {
            width: 24px;
            height: 26px;
            padding-left: 13px;
            padding-right: 13px;
            border-radius: 17px;
        }

        .actions-btn {
            .p-splitbutton-defaultbutton {
                display: none;
            }

            .p-splitbutton-menubutton {
                border-color: #ddd;
                border-left: 1px solid #ddd;
                border-radius: 2px !important;
                gap: unset;
                grid-gap: unset;
                background: #eee;
                min-width: 26px !important;
                width: 26px !important;
            }
        }

        .requirement-groups-table {
            width: 100%;
            border-spacing: 0px;

            thead {
                position: sticky;
                top: 70px;
                z-index: 1000;
            }

            th {
                background-color: #eee;
                font-weight: normal;
                font-size: 0.8em;
                color: #666;
            }

            td .r .c {
                width: 100%;
                ;
            }

            td input {
                width: 100%;
                border: 0px solid;
                background: transparent;
                box-shadow: unset;
            }

            th,
            td {
                padding: 4px;
                border: 1px solid #e2e2e2;
            }
        }
    }
}

#criteria {

    .p-editor-toolbar {
        display: none !important;
    }

    .p-editor-content {
        border-top: 1px solid #dee2e6;
    }
}

.exclude-chips{
    display: flex;
    .exclude-users-chips{
        width: 90%;
        .p-chips-multiple-container {
            width: 100%;
        }
    }
    .exclude-users-chips-button{
        width: 10%;
        min-width: 40px;
    }
}