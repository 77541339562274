.CreateRequirement {
  .files-area {
    background-color: #fff;
    border: 1px dotted #bbb;
    border-radius: 4px;
    color: #888;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    text-align: center;

    i {
      font-size: 2em;
    }
  }

  .selected-file {
    label {
      font-weight: bold;
      width: 200px;
    }
    .value {
      width: 200px;
    }
  }

  .color-dot {
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
    border-radius: 50%;
    display: flex;
    margin-left: 5px;
    margin-top: 5px;
  }
}