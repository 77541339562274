
#cookiesBanner {
    text-align: center;
    z-index: 0;
    background-color: white;
    padding: 1%;
    width: 100%;
    h3{

    }
    .buttonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: 15%;
            margin-left: 2.5%;
        }
    }
}

.p-dialog {
   width: 50%;

}