$notifications-bg-color: rgb(243, 224, 174);
$notifications-bg-color-darker: #ecd18c;

strong.g {
    color: #7a7a7a;
}

.bg-danger {
    background-color: #c82f0f;
    color: white;
}

.p-chip.danger {
    background: #c82f0f;
    color: white;
}
.p-chip.success {
    background: #0f8f20;
    color: white;
}
.p-chip.info {
    background: #2196f3;
    color: white;
}

.bg-gray {
    background: #eee;
}

.bg-white {
    background: white;
}

.resource-status-1, .resource-status-2,
.resource-status-3, .resource-status-4,
.resource-status-5, .resource-status-6 {
    width: 100%;
}

.resource-status-1, .resource-status-5 {
    background-color: #c82f0f;
}

.resource-status-2, .resource-status-4 {
    background-color: #0f8f20;
}

.resource-status-3 {
    background-color: #333333;
}

.resource-status-6 {
    background-color: #f5d20d;
}

.resource-status-7 {
    background-color: #2196f3;
}

.notifications-bg, .notifications-bg:hover, .notifications-bg:focus {
    background-color: $notifications-bg-color;
}
