.ValidateCommunication {
    .actions {
        display: flex;
        flex-direction: row;
        background-color: #eee;

        button.primary {
            background-color: #0f8f20;
            color: white;
        }
    }

    .validate-container {
        padding: 20px;
    }
}