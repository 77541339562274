.debug.wide-theme {
    $a-color: #484848;
    $b-color: #343434;

    // $a-color: #589126;
    // $b-color: #37650f;

    .JobCard .header {
        background-color: #3d3d3d;
    }

    .JobCard .header .p-splitbutton-menubutton {
        background-color: #232323;
    }

    .JobCard {
        background-color: #bebebe;
        border-color: #aaa;
    }

    .breadcrumb-container {
        border-bottom: 1px solid #282828;
    }

    #breadcrumb-right {
        select.transparent {
            color: white;
        }
        option.font {
            color: black;
        }
    }

    .JobPanelActions {
        background-color: #555;
        color: white;

        .action {
            color: white;
        }

        .Toggler .title {
            border-bottom: 1px solid #646464;
            color: #ddd;
        }

        .section {
            color: #bbb;

            .title {
                padding-bottom: 4px;
                border-bottom: 1px solid #636363;
            }
        }
    }

    #home .bottom {
        margin-top: 10px;
        margin-bottom: 100px;
        padding: 60px;
        color: #fff;
        border-radius: 6px;
        // background: linear-gradient(90deg, $b-color, $a-color 50%, $b-color);
    }

    .actions {
        background-color: #565656;
        button {
            color: #fafafa;
            background-color: #505050;
            border: 1px solid #444;
        }
        button:hover {
            border-color: #222 !important;
            background: #333;
        }
        .p-panel .p-panel-header {
            background: #333333;
            color: #c3c3c3;
            border: 1px solid #191919;
        }
        .p-panel-content {
            border: 1px solid #3d3d3d;
            background: #7b7b7b;
        }
        .panel-contractor .title-box {
            color: #aaa;
            border-bottom: 1px solid #505050;
        }
        .panel-contractor .title-box .fas, .panel-contractor .title-box .fa {
            color: #b2b2b2;
        }
        .panel-contractor .title-box .title {
            color: #b2b2b2;
        }
        .panel-contractor .contractor-name {
            color: #232323;
            background-color: #7b7b7b;
        }
        .panel-contractor {
            border: 1px solid #333;
            background-color: #444;
        }
    }

    .footer-container {
        background-color: #555;
        .copyright {
            a {
                color: #eee !important;
            }
            color: #eee;
        }
    }

    #footer {
        .desc {
            color: white;
        }
    }

    .header-menu {
        background-color: $a-color !important;

	a:hover {
            background: $a-color !important;
            background-color: $a-color ;
        }
        a, ul {
            background: $a-color;
            background-color: $a-color ;
        }

        .p-submenu-icon, .p-menuitem-icon, .p-menuitem-text {
            color: white !important;
        }
    }

    .breadcrumb-container {
        border-top: 1px solid $b-color !important;
        background-color: $b-color !important;

        li, li::before, .p-menuitem-text, .p-submenu-icon, .p-menuitem-icon, .p-breadcrumb-chevron {
            color: white !important;
        }
        i {
            color: white;
        }
        button {
            color: #dedede;
        }
        button:hover {
            color: white;
            text-shadow: none;
        }
    }
}
.wide-theme {
    $accent-color: #51a127;
    $border-radius: 4px;

    height: 100vh;
    display: flex;
    flex-direction: column;

    .light-shadow {
        box-shadow: 2px 3px 6px 0px rgba(129, 129, 129, 0.53);
    }

    .logos {
        background-color: white;
    }
    #container {
        width: 100%;
        margin: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #f7f7f7;

        .ContractorsList{
                width: 99.4%;
        }
    }

    .p-breadcrumb {
        background-color: #f1f1f1;
        padding: 10px;
        font-size: 0.8em;
        padding-left: 38px;
    }

    button {
        display: flex;
        gap: 7px;
        justify-content: center;
        border: 1px solid #aaa;
        border-radius: $border-radius;
        color: #6a6a6a;
        background: #eee;
        padding: 6px;
        font-size: 12px;
        padding-left: 12px;
        cursor: pointer;
        padding-right: 12px;
        min-width: 100px;
    }
    button.slim {
        padding: 4px;
        padding-right: 10px;
        padding-left: 10px;
    }
    button.slim.flat {
        padding: 4px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 3px;
        background: #ececec;
        border: 1px solid #cecece;
    }
    button.slim.flat.primary {
        background: #6eb747;
        border: 1px solid #53972e;
    }
    button:hover {
        border-color: #666;
    }
    button.primary {
        border: 1px solid #589126;
        color: white;
        background: #62ab3b;
        // background: linear-gradient(180deg, #6eb747, #559b30, #51a127);
    }
    button.primary:disabled {
        border: 1px solid #93ca62;
        color: white;
        background: #bbd6ac;
    }

    button.alt {
        border: 1px solid #e8c15e;
        background: linear-gradient(180deg, #ffe197, #ffd66f, #fbc02d);
    }
    button.plain {
        background: #eee;
        background-color: #eee;
        border: 1px solid #dfdfdf;
        color: #939393;
    }
    button.plain.alt {
        border: 1px solid #e8c15e;
        background:  #fbc02d;
        color: black;
    }
    button.rounded {
        border-radius: 15px;
    }

    button.resource {
        border-radius: 15px;
        height: 1%;
        margin-left: 1%;
        margin-right: 1%;
    }

    .chip {
        height: 100%;
    }

    .tool-btn {
        border-radius: 0px;
        border: 0px;
        background: transparent;
    }
    .breadcrumb-container {
        button {
            border-radius: 0px;
            border: 0px;
            background: transparent;
        }
        button:hover {
            border: 0px solid;
            box-shadow: none;
            color: black;
            text-shadow: 1px 1px 2px #ddd;
        }
    }
    .p-tabview, .p-tabview .p-tabview-nav {
        background-color: #f7f7f7;
    }
    .p-tabview .p-tabview-nav {
        border: 0px solid;
        background-color: transparent;
    }
    .p-tabview .p-tabview-nav li p-tabview-nav-link {
        padding: 12px;
    }
    .p-tabview .p-tabview-nav li {
        margin-right: 0;
        border: 1px solid #ccc;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .p-treetable button.p-treetable-toggler {
        display: unset;
    }
    .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
        width: 32px;
        height: 24px;
        margin-right: 10px;
        min-width: 32px;
    }
    .p-treetable .p-treetable-thead > tr > th {
        padding: 6px;
    }
    .p-treetable .p-treetable-tbody > tr > td {
        padding: 6px;
    }
    .p-tabview-title {
        font-size: 0.8em;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        background-color: transparent;
    }
    .p-unselectable-text, .p-unselectable-text a {
        border: 0px solid !important;
        background-color: transparent;
    }
    .p-tabview .p-tabview-nav li a {
        padding: 12px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }
    .p-tabview .p-tabview-nav .p-tabview-selected .p-tabview-nav-link {
        background: #ededed;
        border: 1px solid #cccccc !important;
        border-bottom: 0px solid !important;
        border-color: #ededed;
        color: #787878;
    }
    .p-tabview .p-tabview-panels {
        background: #ededed;
        border: 1px solid #ccc;
    }
}
