.adminUsers{
    .p-paginator-pages{
        display: flex;
    }

    .p-datatable-scrollable-header-box{
        margin-right: 0px !important;
    }

}

.remove_user{
    .confirm_text {
        padding: 1% !important;
    }

    .p-buttonset{
        display: flex;
        justify-content: end;
        background-color: #f3f3f3;
    }
}