.EditWorkerContainer {
    height: 100%;

    .form-container {
        height: 100%;
        display: grid;
        grid-template-columns: auto 400px;
    }

    .actions {
        border-left: 1px solid #ccc;
        background-color: #eee;
        padding-left: 10px;
        padding-right: 25px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;

        .buttons .p-panel-content {
            display: flex;
            flex-direction: row;
            justify-content: right;
            gap: 6px;
            flex-wrap: wrap;
        }
    }

    small {
        text-align: end;
    }
}