@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;800&display=swap');
.notifications-center {
    padding: 20px;

    .notifications {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 80vh;
        overflow: auto;

        .p-tag {
            width: 150px;
        }
    }

    .message-date {
        text-align: center;
        background-color: #e7be58;
        padding: 4px;
        border-radius: 10px;
        min-width: 110px;
    }

    .notifications-title {
        color: #aaa;
        margin-bottom: 10px;
        font-weight: 100;
        font-size: 0.7em;
        display: flex !important;
        align-items: center !important;
        font-family: 'Fira Sans', sans-serif !important;
        font-size: 1.4em;
        font-weight: 1000;

        img {
            margin-left: 10px;
        }

        .p-tag.small {
            margin-left: 5px;
            min-width: 2rem;
            height: 2rem;
            line-height: 2rem;
            font-size: 0.7em;
            border: 1px solid #aaa;
            border-radius: 50%;
        }

        .search-filters {
            i {
                font-size: 0.7em;
                background-color: transparent;
                border: 0px;
            }
        }
    }

    .message.success {
        background-color: rgb(177, 245, 198);
        .close-handle {
            border-left: 1px solid rgb(147, 233, 154);
            color: green;
        }
    }
    .message.error {
        background-color: rgb(253, 200, 200);
        .close-handle {
            border-left: 1px solid rgb(233, 164, 147);
            color: rgb(85, 2, 2);
        }
    }
    .message.info {
        background-color: rgb(200, 232, 253);
        .close-handle {
            border-left: 1px solid rgb(147, 209, 233);
            color: rgb(2, 59, 85);
        }
    }

    .message {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        background-color: antiquewhite;
        padding: 6px;
        font-size: 0.8em;
        border-radius: 4px;
        color: #555;
        align-items: center;

        .close-handle {
            cursor: pointer;
            border-left: 1px solid rgb(235, 203, 165);
            padding-left: 6px;
            color: rgb(148, 112, 65);
        }
    }
}