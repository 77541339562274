.JobHasContractors {
    th {
        font-size: 0.7em;
        text-align: center !important;
    }

    .td-indicator {
        width: 74px !important;
    }

    .td-status {
        width: 100px !important;
        text-align: center !important;
    }

    table {
        font-size: 0.7rem;
    }

    .resource-status {
        width: 90px;
        font-size: 0.65rem;
    }
    .current_status_type {
        max-width: 100px;
    }
    .job-start-date {
        max-width: 100px;
    }
    .job-shipment-number {
        max-width: 100px;
    }
    .job-description {
        max-width: 90px;
    }
}

.jhc {
    .pad {
        i {
        padding-left: 0px !important;
        }
    }
}

.current_status_type {
    width: 160px !important;
    text-align: center !important;
    div {
        width: 100% !important;
    }
}

.disabled-tag {
    background-color: #e9ecef !important;
    color: #e9ecef !important;
    border-color: #e9ecef !important;
}
