.JobInformationLoaded {
  .message {
    border-radius: 6px;
    border: 1px solid #cce3c6;
    color: #466920;
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 60px;
  }

  i {
    font-size: 2em;
    color: #aaa;
    margin-bottom: 20px;
  }

  .button.primary {
    text-decoration: none;
    border-radius: 4px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    border: 1px solid #3c7202;
    background-color: #4a8f00;
  }
}