@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;800&display=swap');

.login-content {
    .p-panel-content {
        width: 100%;
        border: 0px solid black !important;
        background: transparent !important;
        font-family: Arial !important;
    }
    p-panel {
        width: 100% !important;
    }

    .login-header {
        color: grey !important;
        background: transparent !important;
        border: 0px solid black !important;
        font-family: 'Fira Sans', sans-serif !important;
        font-weight: 1000;
        font-size: 1.6em !important;
        margin-left: 10px;
    }

    .inp {
        background:  transparent;
        box-shadow: none;
        border: 0;
        outline: 0;
        border-radius: 0;
        border-bottom: 2px solid #8cc61e;
    }

    .log-footer {
        display: flex;
        width: 100%;
        grid-gap: 25px;
        gap: 25px;
        justify-content: center !important;
        margin-top: 5px;
    }
}