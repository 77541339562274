.Toggler.right {
  .title {
    display: flex;
    flex-direction: row;
    flex-flow: row-reverse;
    i {
      margin-left: 20px;
    }
  }
}

.Toggler {
  color: #444;

  .title {
    cursor: pointer;
    i {
      margin-right: 20px;
    }
    border-bottom: 1px solid #ddd;
  }
}