.margin-auto {
    margin: auto;
}
.r {
    display: flex;
    flex-direction: row;
}
.c {
    display: flex;
    flex-direction: column;
}
.e {
    flex: 2;
    width: 100%;
}
.e-ch * {
    flex: 1;
}
.e-2, .fx-1 {
    flex: 1;
}
.c.g-4, .r.g-4 {
    gap: 4px;
}
.c.g-5, .r.g-5 {
    gap: 5px;
}
.c.g-10, .r.g-10 {
    gap: 10px;
}
.c.g-20, .r.g-20 {
    gap: 20px;
}
.c.g-30, .r.g-30 {
    gap: 30px;
}
.c.g-40, .r.g-40 {
    gap: 40px;
}
.c.g-50, .r.g-50 {
    gap: 50px;
}
.c.g-60, .r.g-60 {
    gap: 50px;
}
.c.g-75, .r.g-75 {
    gap: 75px;
}
.r.v-center, .r.vc {
    align-items: center;
}
.c.h-center, .c.center {
    align-items: center;
}
.c.h-right, .c.right {
    align-items: flex-end;
}
.r.h-center, .r.center {
    justify-content: center;
}
.c.v-center {
    justify-content: center;
}

.fwrap {
    flex-wrap: wrap;
}

.footer {
    background: #efefef;
    border-top: 1px solid #cfcfcf;
}

.r-end {
    justify-content: flex-end;
}

.grid-4 {
    display: grid;
    grid-template-columns: 90px auto 90px auto;
    align-items: center;
}
.grid-4.gap {
    gap: 20px;
}

.grid-2.gap {
    gap: 20px;
}
.grid-2 {
    display: grid;
    grid-template-columns: 30% auto !important;
}
.grid-2 label {
    justify-self: end;
}
.grid-2-50p {
    grid-template-columns: 50% auto !important;
}
.r-wrap {
    flex-wrap: wrap;
}

.c-sc, .c.sc {
    display: flex;
    flex-direction: column;
    width: calc(100% - 16px);
}

.colspan-2 {
    grid-column: 1 / 3;
}

.c.vertical-top {
    justify-content: flex-start;
}
