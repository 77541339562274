.JobCard {
  min-width: 360px;
  max-width: 420px;
  background-color: #efefef;
  border: 1px solid #c3c3c3;
  font-size: 0.7em;
  display: flex;
  flex-direction: column;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .job-code {
    font-weight: bold;
    font-size: 1.4em;
  }

  .job-status-1 {
    background-color: red;
    color: white !important;
  }

  .delete-mr {
    margin-right: 5px;
  }

  .status-mr {
    width: 175px;
    font-size: 1.4em;
  }

  .delete-ml {
    margin-left: 2px;
  }

  .job-status-2 {
    background-color: #c07e03;
    color: white !important;
  }

  .job-status-3 {
    background-color: green;
    color: white !important;
  }

  .job-status-4 {
    background-color: rgb(65, 62, 62);
    color: white !important;
  }

  .status {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-tag {
    min-width: 42px;
  }

  .data {
    flex: 1;
    padding: 10px;
    display: grid;
    grid-template-columns: 30% auto;
    gap: 20px;
    border-bottom: 1px solid #bbb;

    label {
      text-align: right;
      font-weight: bold;
    }
  }

  .header {
    padding-left: 10px;
    padding-right: 0px;
    background-color: #989898;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .header {
    .p-splitbutton-menubutton:hover {
      background: #aaa !important;
    }
    .p-splitbutton-menubutton {
      border: 0px solid;
      border-top-right-radius: 2px !important;
      width: 32px !important;
      height: 32px !important;
      background: #888;
      color: white;
    }
  }
  .p-splitbutton-defaultbutton {
    display: none;
  }
  .p-splitbutton-menubutton {
    padding: 3px !important;

    .p-button-label {
      display: none;
    }
  }

  .indicators {
    padding: 10px;
    display: grid;
    grid-template-columns: 50% auto;
    grid-gap: 10px;
    background-color: #e3e3e3;

    .indicator {
      display: flex;
      gap: 10px;
      flex-direction: row;
      align-items: center;

      label {
        font-weight: bold;
        flex: 1;
        text-align: right;
      }
    }
  }
}

.debug .JobCard {
  .indicators {
    background-color: #d3d3d3;
    border-top: 1px solid #aaa;
  }
}