.ReportOutputTableComponent {
    .subreport-header {
        background-color: #eee;

        .e {
            text-align: center;
        }
    }

    table > tbody {
        overflow-y: scroll;
        overflow-x: scroll;
    }

    .ta-j {
        text-align: justify !important;
    }

    .w75p {
        width: 75px;
    }

    .w100p {
        width: 100px;
    }

    .w300p {
        width: 300px;
    }

    .f-4 {
        min-width: fit-content;
        width: 4%;
    }

    .f-5 {
        min-width: fit-content;
        width: 5%;
    }
    .f-7_5 {
        width: 7.5%;
    }
    .f-10 {
        width: 10%;
    }
    .f-15 {
        width: 15%;
    }
    .f-20 {
        width: 20%;
    }
    .f-25 {
        width: 25%;
    }
    .f-30 {
        width: 30%;
    }
    .f-40 {
        width: 40%;
    }

    .ta-l {
        text-align: left !important;
    }

    //! Barra de windows = 149px
    // Monitor pantalla completa 1080px - (barra windows +  barra superior navegador)
    @media only screen and (min-height: 921px) {
        table > tbody {
            overflow-y: scroll;
            height: 66vmin;
        }
    }
}

.reports-27 {
    .p-datatable-tbody {
        height: fit-content !important;
    }

    .boolean-true{
        background-color: #64c27b !important;
        color: white;
        width: 100%;
    }

    .boolean-false{
        background-color: crimson !important;
        &::before{
            content: '✘';
            color: white;
            width: 100%;
        }
    }
}

.graphicInformLayout {
    .p-datatable-thead{
        tr{
            th:last-child{
                padding-left: 26% !important;
            }
        }
    }
}

.graphicInformLayout2 {
    .p-datatable-thead{
        tr{
            th:last-child{
                padding-left: 18% !important;
            }
        }
    }
}

.graphicInformLayout3 {
    .p-datatable-thead{
        tr{
            th:last-child{
                padding-left: 15% !important;
            }
        }
    }
}