.ViewResourceHistory {
    .body {
        max-height: 80vh;
        overflow-y: auto;
    }

    .status-type-4 {
        color: #bb0000;
    }

    .danger {
        font-weight: bold;
        color: #bb0000;
    }
}