.wide-theme {
    #main-menu {
        display: flex;
        flex-direction: column;

        .p-menubar {
            padding: 0;
            background: unset;
            color: #495057;
            border: 0px solid white;
            font-size: 12px;
            flex: 1;
            padding-left: 20px;
            padding-right: 20px;
        }

        .p-menu-separator {
            flex: 1;
        }

        .p-menubar-root-list {
            flex: 1;
            width: 100%;
        }

        .logos {
            display: flex;
            flex-direction: row;
            padding-top: 4px;
            padding-left: 16px;
            padding-right: 0px;
        }

        .logos {
            height: 60px;
            .gstore {
                width: 218px;
                height: 42px;
            }
            .ils {
                width: 300px;
            }
        }

        .header-menu {
            grid-column: 2/3;
            width: 100%;
            padding: 0px;
            margin: auto;
            margin-bottom: 0px;
            border: 0 none;
            border-top: 1px solid #e6e6e6;
            background: #f7f7f7;
        }
    }
}