.JobForm {
  .EmbedProperties {
    .r.vc {
      label {
        width: 143px;
      }

      input[type='text'] {
        width: 248px;
      }
    }

    .bool-property {
      label {
        border: 0px;
        background: transparent;
        width: 100% !important;
      }
    }
  }

  .add-department-btn {
    background: #e2e2e2;
    border-radius: 4px;
    border: 1px solid #d0d0d0;
  }

  .add-action {
    padding-top: 10px;
  }

  .right-align {
    text-align: right;
  }

  .date-error {
    right: 30px !important;
    top: -22px !important;
  }
}

.azsa_dinamic_properties {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;

  .Properties:first-child {

    grid-column: 1 / 2;
  }

  .Properties:nth-child(2) {
    grid-column: 2 / 3;

  }

  .Properties:nth-child(3) {
    grid-column: 3 / 4;
  }


  .Properties {
    grid-column: 1 / 4;
  }

  .p-inputswitch {
    order: 2 !important;
    align-self: center;
  }

  .label-rr {
    order: -1 !important;

  }

  label {
    width: 200px !important;
  }

  .booltext-property {
    width: 150%;

    .e {
      display: none;
    }

    label {
      margin-right: 10px;
    }
  }
}