.dark-theme {
    height: 100vh;
    display: flex;
    flex-direction: column;

    #container {
        width: 100%;
        margin: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #333333;
    }

    .footer-container {
        border-top: 56px solid #333 !important;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover
    {
        background: #333;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
        background: #222;
    }

    .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link,
    .p-menubar .p-submenu-list {
        background: #333;
    }

    .p-datatable .p-datatable-header {
        background: #555;
        border: 0px solid;
    }

    .p-datatable-scrollable-header-box {
        background-color: #666;
        margin-right: 0px !important;
    }
    .p-datatable .p-datatable-thead th {
        background-color: #666;
        color: white;
    }

    #graphiql .topBar {
        background-color: #666;
    }
}

