.ManageMachineries {
    height: 100%;

    .left-col {
        min-width: 35%;
        border-right: 1px solid #ddd;
        background: #eee;
    }

    h3 {
        color: #999999;
        font-size: 14px;
        font-weight: normal;
    }

    .selected-machinery {
        .left-col {
            min-width: 25%;
            border-right: 1px solid #ccc;
            background-color: #eee;

            td {
                padding: 10px !important;
            }

            td.selected {
                background-color: antiquewhite;
            }
        }

        .main {
            min-width: 75%;
        }
    }
}

.machineries-table {
    width: 100%;
}

.job_machinery_tooltip {
    background-color: white !important;
    border: 1px solid black !important;

    .p-tooltip-arrow {
        display: none !important;
    }

    .p-tooltip-text {
        background-color: white !important;
        color: black !important;
        font-size: small !important;
        box-shadow: none !important;
    }
}