.p-card.slim {
    .p-card-body {
        padding: 0px;
    }

    .p-card-content {
        padding: 0px;
    }
}
.p-card.title {
    .p-card-header {
        padding: 6px;
        background-color: #f8f9fa;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-top: 1px solid white;
        color: #919191;
        font-size: 0.8em;
        border-left: 1px solid white;
        border-right: 1px solid white;
        text-align: center;
    }
}

.p-card.f {
    display: flex;
    flex-direction: column;

    .p-card-body {
        flex: 1;
    }
    .p-card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.p-card {
    .footer {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.p-card.pd-header {
    .p-card-header {
        padding: 20px;
    }
}

.p-card.bg-header {
    .p-card-header {
        background: #f5f5f5;
        border-bottom: 1px solid #ddd;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        color: #777;
    }
}