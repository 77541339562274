.JobData {
  #job-embed-properties {
    grid-column: 1 / 3;
  }

  .colspan-2 {
    label {
      width: calc(30% + 10px) !important;
    }
  }

  .properties {
      label {
        width: 143px;


      input[type='text'] {
        width: 248px;
      }
    }

    .bool-property {
      label {
        border: 0px;
      }
    }
  }

  .EmbedProperties {
    .r.vc {
      display: grid;
      grid-template-columns: 30% auto 0;
      grid-gap: 10px;
      align-items: center;
    }
    .text-property {
      margin-bottom: 8px;
    }
    .p-inputswitch {
      margin-left: calc(30% + 10px);
      margin-bottom: 8px;
    }
    .label {
      padding-right: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 4px;
      font-size: 0.8em;
      font-weight: 400;
      text-align: right;
    }
  }
}

.form-jobData{
  grid-template-columns: auto auto !important;
}

.azsaJobData {
  #job-embed-properties{
    grid-column: 2 / 3;
  }

  .p-inputswitch-checked{
    input[type="text"]{
      width: 450px;
    }
  }

  .booltext-property {
    grid-column-start: 1;
    grid-column-end: 4;
}

  #workShiftId{
    width: 150px;
  }

  #contractTypeId{
    width: 150px;
  }

  .bool-property, .booltext-property{

    label{
      order: -1;
      color: #8b8b8b !important;
      background-color: rgb(239, 239, 239);
      padding:8px;
    }
      .p-inputswitch{
        margin-left: 15px;
        align-items: center;
      }
  }
  .properties{
    display: grid;
    grid-template-columns: repeat(3, 1fr );
    margin: 2% 0;
  }
}
