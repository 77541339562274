.ValidateRequirement {
    label {
        width: 90px !important;
    }
    
    textarea {
        min-width: 320px !important;
    }
}

.ValidateDate{
    display: flex;
    align-items: center;
    i{
        margin-left: 10px;
    }
}