.a-left {
    table {
        table-layout: auto !important;
    }
    .p-datatable-tbody{
        tr{
            .center {
                .pointer{
                    padding-right: 100px;
                }
            }
        }

    }
}

.codes {
    width: 70px !important;
    text-align: center;
}

.important {
    width: 150px !important;
    text-align: center;
}
