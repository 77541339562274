.Properties {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    padding: 20px;
    font-size: 0.8em;

    h2 {
        text-align: right;
        font-size: 0.9em;
        color: #666;
        font-weight: normal;
        border-bottom: 1px solid #ccc;
    }

    .grid-2 {
        display: grid;
        grid-template-columns: 50% auto;
    }

    .r.properties, .c.properties, .grid-2.properties {
        font-size: 0.9em;
        flex-wrap: wrap;
        gap: 10px;
        .r {
            align-items: center;
            gap: 10px;
        }
    }
}