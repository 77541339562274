.ViewRequirementDocumentContainer {
    .form-1 {
        label {
            width: 120px !important;
            text-align: left;
        }
        align-items: flex-start;
        .value {
            flex: 1;
            text-align: right;
        }

        .vc {
            width: 100%;
            word-break: break-all;
        }
    }
}

.EditRequirements {
    .requirement-row {
        min-height: 32px;
    }

    .p-tabview-nav-container {
        position: relative;
        top: 1px;
    }

    .legend {
        border-radius: 5px;
        background-color: #ededed;
        margin-top: 10px;
    }

    .expired {
        background-color: #e64634;
        color: #fff !important;
        padding: 2px;
        border-radius: 3px;
        padding-left: 10px;
        padding-bottom: 3px;
        padding-right: 10px;
    }

    .expiration-date {
        .fas {
            margin-right: 6px;
        }
        color: rgb(121, 26, 26);
    }

    .status-actions-btn {
        .p-splitbutton-menubutton {
            height: 26px;
        }
    }

    .actions-btn {
        .p-splitbutton-defaultbutton {
            display: none;
        }
        .p-splitbutton-menubutton {
            border-left: 1px solid #bbb !important;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            gap: unset;
            grid-gap: unset;
            width: 30px !important;
            padding: 3px !important;
        }
    }

    .documents-cell {
        display: flex;
        flex-direction: row;
        gap: 6px;

        .cell {
            border: 1px solid #ddd;
            border-radius: 16px;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 3px;
            padding-bottom: 3px;
            cursor: pointer;
        }

        .cell0 {
            color: #36972c;
            border-color: #89ba7b;
            background: #d6efd4;
        }
        .cell1 {
            color: #888b61;
            border-color: #c6ca93;
            background: #edefd4;
        }
        .cell2 {
            color: #888b61;
            border-color: #c6ca93;
            background: #edefd4;
        }
        .cell3 {
            color: rgb(120, 196, 112);
            border-color: rgb(120, 196, 112);
        }
        .cell4 {
            color: rgb(120, 196, 112);
            border-color: rgb(120, 196, 112);
        }
    }

    .odd {
        background-color: #eee;
    }

    .even {
        background-color: #f7f7f7;
    }

    // .requirement-bottom{
    //     border-bottom: solid 1px #ccc;
    // }

    .tag-width {
        width: 100px;
    }

    .p-tag.status-type-4 {
        background-color: rgb(230, 70, 52);
    }
    .p-tag.status-type-3 {
        background-color: rgb(32, 150, 16);
    }
    .p-tag.status-type-2 {
        background-color: #1c63b0;
    }
    .p-tag.status-type-1 {
        background-color: rgb(150, 150, 150);
    }

    .st-4 .p-splitbutton-menubutton:hover {
        background: rgb(173, 49, 35) !important;
    }
    .st-4 .p-splitbutton-menubutton {
        background: rgb(201, 60, 46);
        border: 1px solid #d94b4b;
        color: white;
    }

    .status-type-expired {
	background: rgb(230, 70, 52);
    }

    .st-expired .p-splitbutton-menubutton {
	background: rgb(201, 60, 46);
        border: 1px solid #d94b4b;
        color: white;
    }
    .st-expired .p-splitbutton-menubutton:hover {
	background: rgb(173, 49, 35) !important;
    }

    .st-warning .p-splitbutton-menubutton:hover {
        background: #dc8e3e !important;
    }
    .st-warning .p-splitbutton-menubutton {
        background: #dc8e3e;
        border: 1px solid #dc8e3e;
        color: white;
    }

    .st-3 .p-splitbutton-menubutton:hover {
        background: rgb(22, 98, 12) !important;
    }
    .st-3 .p-splitbutton-menubutton {
        background: rgb(30, 128, 17);
        border: 1px solid green;
        color: white;
    }

    .st-2 .p-splitbutton-menubutton:hover {
        background: #145192 !important;
    }
    .st-2 .p-splitbutton-menubutton {
        background: #145192;
        border: 1px solid #11447a;
        color: white;
    }

    .st-1 .p-splitbutton-menubutton:hover {
        background: rgb(110, 110, 110) !important;
    }
    .st-1 .p-splitbutton-menubutton {
        background: rgb(150, 150, 150);
        border: 1px solid rgb(130, 130, 130);
        color: white;
    }

    .hidden-avatar{
        img {
            display: none !important;
        }
    }
}
