.no-scroll {
    overflow: hidden;
}
.inline-block {
    display: inline-block;
}
.w80p {
    width: 80%;
}
.mw300x {
    min-width: 300px !important;
}
.sm.pd {
    padding: 4px;
}
.sm.pd-right {
    padding-right: 4px;
}
.sm.pd-left {
    padding-left: 4px;
}
.md.pd-bottom {
    padding-bottom: 8px;
}
.md.pd-left {
    padding-left: 8px;
}
.lg.pd-right {
    padding-right: 32px;
}
.sm.pd-top {
    padding-top: 4px;
}
.sm.pd-btm, .sm.pd-bottom {
    padding-bottom: 4px;
}
.sm.mr, .sm.mrg {
    margin: 4px;
}
.sm.mr-left {
    margin-left: 4px;
}
.sm.mr-right {
    margin-right: 4px;
}
.sm.mr-top {
    margin-top: 4px;
}
.md.mr-right {
    margin-right: 8px;
}
.md.mr-top {
    margin-top: 8px;
}
.lg.mr-top {
    margin-top: 16px;
}

.md.pd {
    padding: 8px;
}

.md.mr {
    margin: 8px;
}

.lg.mr {
    margin: 18px;
}

.md.pd-top {
    padding-top: 8px;
}

.md.pd-right {
    padding-right: 8px;
}
.md.mr-top {
    margin-top: 8px;
}

.xlg.pd {
    padding: 100px;
}

.xlg.mr {
    margin: 100px;
}

.lg.mr {
    margin: 50px;
}

.lg.pd {
    padding: 20px;
}

.lg.pd-top {
    padding-top: 20px;
}

.w100p, .we, .w-100p {
    width: 100%;
}

.h-100p, .h100p, .he {
    height: 100%;
}

.no-padding {
    padding: 0px !important;
}

.hm-70 {
    min-height: 70vh;
}

.wx-400 {
    width: 400px;
}
.wx-600 {
    width: 600px;
}
.wx-800 {
    width: 800px;
}
.wx-134 {
    width: 134px !important;
}