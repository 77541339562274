.ViewWorkers {
    height: 100%;

    .relationJobs {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: auto;
    }

    h3 {
        color: #999999;
        font-size: 14px;
        font-weight: normal;
    }

    .selected-worker {
        .left-col {
            min-width: 25%;
            border-right: 1px solid #ccc;
            background-color: #eee;

            td {
                padding: 10px !important;
            }
            td.selected {
                background-color: antiquewhite;
            }
        }
        .main {
            min-width: 75%;
        }
    }

    .EmbedProperties {
        .properties {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 10px;
            justify-items: center;

            .r {
                gap: 10px;
                align-content: center;
            }
        }
    }

    .w-10 {
        width: 10%;
    }

    .w-25 {
        width: 25%;
    }

    .d-none {
        display: none;
    }

    .workers-table-21{
        width: 100%;
        tr{
            display: flex;

            td:nth-child(1){
                order: 1;
                width: 5%;
            }
            td:nth-child(2){

                order: 3;
                width: 14.5%;
            }
            td:nth-child(3){

                order: 2;
                width: 14.5%;
            }
            td:nth-child(4){

                order: 4;
                width: 14.5%;
            }
            td:nth-child(5){

                order: 5;
                width: 4%;

            }
            td:nth-child(6){

                order: 6;
                width: 14.5%;
            }
            td:nth-child(7){

                order: 7;
                width: 14%;
            }
            td:nth-child(8){

                order: 8;
                width: 14%;
            }
            td:nth-child(9){

                order: 9;
                width: 4%;
            }

        }
        thead{
            background-color: #efefef;
            th:nth-child(1){

                order: 1;
                width: 5%;
            }
            th:nth-child(2){

                order: 3;
                width: 14%;
            }
            th:nth-child(3){

                order: 2;
                width: 13%;
            }
            th:nth-child(4){

                order: 4;
                width: 15%;
            }
            th:nth-child(5){

                order: 5;
                width: 4%;
            }
            th:nth-child(6){

                order: 6;
                width: 14.5%;
            }
            th:nth-child(7){

                order: 7;
                width: 14%;
            }
            th:nth-child(8){

                order: 8;
                width: 14%;
            }
            td:nth-child(9){

                order: 9;
                width: 4%;
            }
        }
    }

    .p-datatable-tbody td.left {
        text-align: left !important;
    }
}