.default-theme {
    #main-menu {
        display: grid;
        grid-template-columns: 20% auto 20%;
        margin-top: 20px;

        .p-menubar {
            padding: 0;
            background: unset;
            color: #495057;
            border: 0px solid white;
            font-size: 12px;
            flex: 1;
        }

        .p-menu-separator {
            flex: 1;
        }

        .p-menubar-root-list {
            flex: 1;
            width: 100%;
        }

        .logos {
            display: flex;
            flex-direction: row;
            gap: 20px;
            grid-column: 2/3;
        }

        .header-menu {
            grid-column: 2/3;
            width: 100%;
            padding: 0px;
            margin: auto;
            padding-bottom: 40px;
            background: #f1f1f1 url(../img/bg-menu.jpg) repeat-x 0% 100%;
            margin-bottom: 0px;
            border: 0 none;
        }
    }
}