.profile-container {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top: 40px;
    width: 800px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 10px;

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3),
                0px 0px 4px rgba(0, 0, 0, 0.1);

    .header {
        background-color: #efefef;
        padding: 10px;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        border-bottom: 1px solid #e2e2e2;
    }

    .data-container {
        display: flex;
        flex-direction: row;
        gap: 40px;
        padding: 20px;
    }

    .data {
        flex: 1;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .footer-actions {
        padding: 20px;
    }
}
