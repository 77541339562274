.FieldValidationForm {
  textarea {
    font-family: Monaco, monospace;
    height: 100%;
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
  }

  .validation-block {
    min-width: 500px;
    padding: 4px;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
  }

  pre.result {
    padding: 20px;
    background-color: #b0dba2;
  }

  .header {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }

  .p-splitbutton-defaultbutton {
    display: none;
    background-color: grey;
  }
  .p-splitbutton-menubutton {
    padding: 3px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;

    .p-button-label {
      display: none;
    }
  }
}