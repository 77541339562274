.ViewMachineries {
    height: 100%;

    .p-overlaypanel{
        z-index: 10;

        select {
            background-color: white;
            border: 1px solid #ccc;
        }

        input[type=text] {
            background-color: white;
            border: 1px solid #ccc !important;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 20px;
            border-radius: 4px;
        }
    }

    .relationJobs {
        display: flex;
        gap: 5px;
        margin: auto;
        align-items: center;
    }

    h3 {
        color: #999999;
        font-size: 14px;
        font-weight: normal;
    }

    .selected-machinery {
        .left-col {
            min-width: 25%;
            border-right: 1px solid #ccc;
            background-color: #eee;

            td {
                padding: 10px !important;
            }
            td.selected {
                background-color: antiquewhite;
            }
        }
        .main {
            min-width: 75%;
        }
    }

    .w-33{
        width: 33%;
    }

    .w-34{
        width: 34%;
    }

    .td-min {
        width: 10%;
    }

    .td-max {
        width: 20%;
    }

    .w-7{
        width: 7%;
    }

    .p-datatable-tbody td.left {
        text-align: left !important;
    }
}