.ManageDocuments {
    height: 100%;

    li.p-treenode-leaf {
        border-bottom: 1px solid #eee;
    }

    .p-tree-toggler {
        width: 32px;
        min-width: 32px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 50px;
        background: #eee;
    }

    .doc-perm {
        width: 18px;
        height: 18px;
        border-radius: 26px;
        display: inline-block;
        margin-right: 6px;
    }

    .doc-perm-legend {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px;

        .doc-perm {
            width: 14px !important;
            height: 14px !important;
            margin-left: 12px;
        }
    }

    .doc-perm.contractor {
        background-color: lightsalmon;
    }

    .doc-perm.internal {
        background-color: cornflowerblue;
    }

    .doc-perm.all {
        background-color: rgb(120, 170, 46);
    }

    .tree-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .tabular-data-labels {
            display: flex;
            gap: 10px;
            font-size: 0.8em;

            .p-tag {
                background: #aaa;
            }
        }

        .work-23 {
            .p-treetable-tbody {
                tr:nth-child(odd) {
                    background-color: #eee;
                }
            }
        }

        .tree-table-headers {
            background-color: #eee;
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-right: 10px;
            align-items: center;
            justify-items: center;
            font-size: 0.8rem;
            color: #888;

            span {
                border-left: 1px solid #ddd;
                padding: 10px;
            }

            .code-header,
            .edition-header,
            .year-header,
            .upload-time-header,
            .date-header {
                border-right: 1px solid #ccc;
                text-align: center;
            }

            .code-header {
                width: 100px;
            }

            .edition-header {
                width: 100px;
            }

            .year-header {
                width: 100px;
            }

            .upload-time-header {
                width: 140px;
            }

            .date-header {
                width: 140px;
            }
        }

        .tabular-data {
            display: flex;
            flex-direction: row;
            width: 540px;
            flex: 1;

            .code,
            .edition,
            .year,
            .upload-time,
            .date {
                width: 100px;
                font-size: 0.8rem;
                display: table-cell;
                border-right: 1px solid #ddd;
                text-align: center;
            }

            .upload-time,
            .date {
                width: 140px;
                display: inline-block;
            }
        }

        .sort-order {
            color: #ccc;
            padding-left: 0;
            padding-right: 6px;
        }

        .td-expand .doc-perm {
            top: 5px;
            position: relative;
        }

        .p-tree {
            flex: 1;
        }

        .td-actions {
            width: 50px;
        }

        .node-icon {
            margin-right: 10px;
        }
    }
}

#sortOrder {

    &~.p-error {
        right: 25px;
    }
}