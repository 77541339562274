.yellow.box {
    background: #feffe0;
    border: 1px solid antiquewhite;
    color: #7a5d15;
    background: linear-gradient(45deg, #fffbd7, #fffee6, #fffbd7);
}

.green.box {
    border: 1px solid #b0dba2;
    color: #2c6419;
    background: linear-gradient(45deg, #b0dba2, #d5edcb, #b9dfaa);
}
.blue.box {
    border: 1px solid #c7dcef;
    background: aliceblue;
    color: #506d9c;
}
.rounded.box {
    border-radius: 4px;
}

.box {
    padding: 20px;
    background: #ededed;
    border: 1px solid #ddd;
}

.border-bottom {
    border-bottom: 1px solid #ccc;
}

.border-top {
    border-top: 1px solid #ccc;
}