.text-sm {
    font-size: 0.8em;
}
.text-sm .p-inline-message-text {
    font-size: 0.8em;
}
.page-subtitle {
    margin: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}
.loading-text {
    color: #666;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.center {
    text-align: center !important;
}
.p-inputtext {
    padding: 6px !important;
}
.p-input-icon-left .p-inputtext {
    padding-left: 2rem !important;
}
.text-ellision {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.p-chip {
    font-size: 0.8em;
    color: white;
}

h2.box-title {
    font-size: 0.9em;
    color: #666;
    font-weight: 200;
    border-bottom: 1px solid #999;
    width: 100%;
}

h3.box-title {
    font-size: 0.9em;
    color: #666;
    font-weight: 200;
    border-bottom: 1px solid #999;
    width: 100%;
}

.p-accordion-header-link {
    font-size: 0.8em;
}

.text-gray {
    color: #666;
}

.mutted {
    color: #999;
}

.strong {
    font-weight: bold;
}