.PdfViewer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .toolbar {
        button.nav {
            border: 0px solid;
        }
        button {
            background-color: white;
            color: #aaa;
        }
        display: flex;
        flex-direction: row;
        position: absolute;
        width: 100%;
        background-color: white;
        align-items: baseline;
    }

    .canvas-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 94vh;
        background-color: white;

        canvas {
            width: 100%;
        }
    }

    .button-group {
        padding-top: 0px;
        button {
            border: 0px !important;
            border-radius: 0px !important;
        }
    }

    .fit-l, .fit-r {
        padding: 3px;
        border-radius: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .fit-l.selected, .fit-r.selected {
        color: #000;
    }

    .fit-l {
        border-top-left-radius: 6px;
        border: 1px solid #ccc;
        border-bottom-left-radius: 6px;
        border-right: 0px;
    }
    .fit-r {
        border-top-right-radius: 6px;
        border: 1px solid #ccc;
        border-bottom-right-radius: 6px;
        border-left: 0px;
    }

    .pages-text {
        font-size: 0.7em;
        color: #888;
    }

    canvas {
        margin-top: 30px;
    }
}