.EditContractorContainer {
    height: 100%;

    .form-container {
        height: 100%;
        display: grid;
        grid-template-columns: auto 400px;
    }

    .EmbedProperties {
        label {
            display: flex !important;
            justify-content: flex-end !important;
            align-items: center !important;
            margin: 0px !important;
            border: 1px solid #cacaca !important;
            background: transparent !important;
            font-size: 0.8em;
            font-weight: 400 !important;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
            text-align: right !important;
            line-height: 23px !important;
            width: 200px !important;
            border: 0px solid !important
        }

        input{
            width: 100%;
        }
    }

    .actions {
        border-left: 1px solid #ccc;
        background-color: #eee;
        padding-left: 10px;
        padding-right: 25px;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;

        .buttons .p-panel-content {
            display: flex;
            flex-direction: row;
            justify-content: right;
            gap: 6px;
            flex-wrap: wrap;
        }
    }
}

.CorrectRemark{
    label{
        width: 200px !important;
        margin-left: 4px !important;
    }
}