.overlay-panel {
    position: fixed !important; /* Position the panel fixed on the screen */
    right: 20px !important;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  }

.footer {
    button {
      color: black;
    }
}

.filters-export{
  padding: 0 15px;
}