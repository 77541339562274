$initial-x-rotation: 10deg;
$initial-y-rotation: 10deg;

.SelectJob {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px;
    width: 100%;
    gap: 100px;

    .work-card {
        cursor: pointer;
        min-width: 300px;
        min-height: 300px;
        font-size: 0.8em;

        button {
            border: 0px;
            background: transparent;
        }

        .r.vc {
            margin-bottom: 10px;
        }

        .p-chip {
            margin-left: 10px;
        }
        label {
            font-weight: 300;
            color: #777;
            text-align: right;
        }
        .value {
            color: #444;
        }

        .white {
            border: 1px solid #ddd;
            padding: 10px;
            background: linear-gradient(180deg, #dddddd, #e2e2e2, #dddddd);
        }

        .name {
            margin-bottom: 10px;
            border-bottom: 1px solid rgb(133, 133, 133);
        }

        user-select: none;
        width: 300px;
        height: 300px;

        transition: 200ms ease-in;
        transform-style: preserve-3d;
        transform: rotateY($initial-y-rotation) rotateX($initial-x-rotation);

        .count-box {
            font-size: 0.7em;
            align-items: center;
            .p-badge {
                font-size: 1em;
                width: 30px;
                border-radius: 4px;
            }
            padding: 10px;
            grid-gap: 5px;
            border-top: 1px solid #bbb;
            display: grid;
            grid-template-columns: 30% 32px 30% 32px;
        }

        .handle-left {
            position: absolute;
        }
        .handle-right {
            position: absolute;
            right: 0px;
        }
        .face {
            padding: 0px;
            position: absolute;
            width: 300px;
            height: 300px;
            background: #ddd;
            border: 1px solid #ccc;
        }
        .face.e2 {
            background: #f2f2f2 !important;
        }
        .face:nth-child(1){
            transform: translateZ(150px);
        }
        .face:nth-child(2){
            transform: rotateX(360deg) translateZ(-150px) rotateY(180deg);
        }
        .face:nth-child(3){
            transform: rotateY(-90deg) translateZ(150px);
            background: rgb(207, 207, 207);
        }
        .face:nth-child(4){
            transform:rotateY(90deg) translateZ(150px);
            background: rgb(201, 201, 201);
        }
        .face:nth-child(5){
            transform: rotateX(90deg) translateZ(150px);
            background: rgb(218, 218, 218);
        }
        .face:nth-child(6){
            transform:rotateX(-90deg) translateZ(150px);
            background: rgb(185, 185, 185);
        }
    }
}
