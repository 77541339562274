@import './dialogs.scss';
@import url('./layout.css');
@import url('./sizes.css');
@import url('./text.css');
@import url('./tables.css');
@import './colors.scss';
@import './controls.scss';
@import './box.scss';
@import './buttons.scss';
@import './cards.scss';
@import './forms.scss';
@import './panels.scss';
@import './theme.wide.scss';
@import './theme.dark.scss';
@import './tooltips.scss';
@import './properties.scss';

.debug-label {
    position: fixed;
    background: red;
    z-index: 1000000000000000000000000;
}
#root {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
}

body {
    overflow-x: hidden;
    padding: 0px;
    margin: 0px;
    font-family: sans-serif;
    background-color: white;
}

.default-theme {
    #container {
        width: 60%;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.pointer {
    cursor: pointer;
}

.breadcrumb-container {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    align-items: center;
    background-color: #efefef;
    padding-right: 30px;
    z-index: 100;

    .p-breadcrumb {
        border: 0px;
        background: unset;
    }
}

#user-breadcrumb {
    display: flex;

    ul {
        list-style-type: none;
        display: flex;
        padding-left: 0px;
        padding-top: 0px;
        margin: 0px;

        li {
            color: #495057;
            font-size: 12.8px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            padding-right: 10px;
        }
        li::before {
            font-family: 'primeicons';
            content: "\e901";
            margin-right: 8px;
            color: #495057;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
        }
    }
}

.relative {
    position: relative;
}

.hide {
    display: none;
}