.no-events {
    pointer-events: none;
}

select.flat, input.flat {
    border-radius: 0px !important;
}
.p-dropdown.transparent, input.transparent {
    border: 0px !important;
    background-color: transparent !important;
    box-shadow: unset !important;
}
.form-header {
    padding: 10px;
    background: #ededed;
    border-bottom: 1px solid #ddd;
    font-size: 0.8em;
    color: #555;
    font-style: italic;
}
.form-2 {
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: 10px;
    align-items: center;
}
.form-4 {
    display: grid;
    grid-template-columns: 30% auto 30% auto;
    grid-gap: 10px;
    align-items: center;
}
.form-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-1 label, .form-2 label, .form-4 label {
    // background: #999;
    color: #8b8b8b;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    font-size: 0.8em;
    font-weight: 400;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: right;
}

.form-1 span.value, .form-2 span.value, .form-4 span.value {
    font-size: 0.8em;
    font-weight: 400;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom: 1px solid #ddd;
    background: #f9f9f9;
    padding: 4px;
}

.form-1 .p-skeleton, .form-2 .p-skeleton, .form-4 .p-skeleton {
    height: 32px !important;
}

.cols-2 {
    grid-column: 1/3;
}

.cols-4 {
    grid-column: 1/5;
}

.p-treeselect {
    height: 32px;
}
.p-treeselect-label {
    font-size: 13px;
    color: #333;
}

input[type=text], input[type=time], input[type=email], input[type=password], input[type=search], input[type=date], input[type=number] {
    line-height: 23px;
}

select, .p-dropdown .p-dropdown-label {
    font-size: 0.8em;
}

input[type=search], input[type=time], input[type=email], input[type=password], input[type=text], textarea, input[type=date], input[type=number]  {
    background-color: rgb(255, 255, 255);
    border-bottom-color: rgb(204, 204, 204);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border: 1px solid rgb(204, 204, 204);
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px 0px inset;
    color: rgb(85, 85, 85);
    display: inline-block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    font-weight: 400;
}

input[type=text]:focus, input[type=time]:focus, input[type=email]:focus, input[type=password]:focus, input[type=search]:focus, textarea:focus, input[type=date]:focus, input[type=number]:focus {
    border-color: rgba(88, 176, 88, 0.8);
    outline: 0;
    outline: thin dotted \9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(88, 176, 88, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(88, 176, 88, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(88, 176, 88, 0.6);
}
.fields-box.bg {
    border-bottom: 1px solid #DDD;
    margin: 2px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-top: 5px;
    .title {
        padding-right: 20px;
    }
}
.fields-box.bg.shadow {
    box-shadow: 1px 0px 5px 1px rgba(8, 8, 8, 0.15);
}

.fields-box.bg.light {
    background: #f7f7f7;
}

.fields-box {
    border-bottom: 1px solid #DDD;
    .title {
        font-size: 0.8em;
        color: #777;
        padding-left: 10px;
        padding-bottom: 8px;
        align-items: center;
        align-content: center;
        gap: 6px;
        display: flex;
        flex-direction: row;
    }
}

.l200 {
    .inputgroup {
        label {
            width: 200px;
        }
    }
    .r {
        label {
            width: 200px;
        }
    }
}
.l300 {
    .inputgroup {
        label {
            width: 300px;
        }
    }
}
.l400 {
    .inputgroup {
        label {
            width: 400px;
        }
    }
}

.inputgroup {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: stretch;

    .c {
        position: relative;
    }
    .p-error {
        position: absolute;
        right: 4px;
        top: 7px;
        font-size: 0.6em;
        background-color: #b0251b;
        padding: 4px;
        color: white;
        border-radius: 3px;
        pointer-events: none;
    }
    .p-error.date {
        right: 32px !important;
    }
    .value {
        border-top: 1px solid #cacaca;
        border-right: 1px solid #cacaca;
        border-bottom: 1px solid #cacaca;
        background-color: white;
        padding-left: 10px;
        border-radius: 4px;
        font-size: 0.9em;
        color: #333;
        padding-top: 6px;
        height: 30px;
    }

    div.c {
        input, select, .p-dropdown, .p-treeselect, textarea, .value {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            width: 100%;
        }
    }
    input, select, div.c, .p-dropdown, .p-treeselect, textarea, .value {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        width: 100%;
    }

    label {
        padding: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
        margin: 0px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: 1px solid #ddd;
        border: 1px solid #cacaca;
        background: #efefef;
    }
}

button.flat, .p-dropdown.flat {
    border-radius: 0px !important;
}

input:read-only, textarea:read-only {
    border-color: #ddd;
    background: #f2f2f2;
}

.label-rr {
    padding: 8px !important;
    border: 1px solid #ccc;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: #efefef;
}

select {
    border-color: #ccc;
    color: #666;
    border-radius: 4px;
}

.right-align{
    text-align: right;
  }


.constractorInvitation {

    width: 100%;

    .inputgroup{

        width: 100%;
    }

    .BeginDate{

        div{
            width: 20%;
        }

        input{
            width: 20%;

        }

      }
}
