.CurrentCommunications {
    padding: 10px;

    .view {
        width: 100%;
        max-height: 60vh;
        overflow: auto;
    }
}

.title {
    color: #888;
    border-bottom: 1px solid #ddd;
    text-align: center;
    margin-bottom: 4px;
}

.file-row {
    color: #555;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 16px;
    margin: 2px;
    display: flex;
    width: 400px;
    gap: 20px;
    i {
        cursor: pointer;
    }
}