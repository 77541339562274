.ManageContractors {
    height: 100%;

    h3 {
        color: #999999;
        font-size: 14px;
        font-weight: normal;
    }

    .selected-contractor {
        .left-col {
            min-width: 300px;
            border-right: 1px solid #ccc;
            background-color: #eee;

            td {
                padding: 10px !important;
            }

            td.selected {
                background-color: antiquewhite;
            }
        }

        .main {
            min-width: 75%;
        }
    }

    .EmbedProperties {

        .bool-property,
        .booltext-property {
            .p-inputswitch {
                margin-left: 120px;
            }

            label {
                width: 100%;
                text-align: left;
            }

            margin-bottom: 4px;
        }
    }
}

.render-2-23 {
    input {
        display: none !important;
    }

    .search-filters {
        display: none;
    }

    .p-inputgroup-addon {
        display: none;
    }
}

.ta-nr {
    resize: none;
}

.suspension-td {
    width: 60px !important;
    text-align: center !important;
}

.manage-contractor-23 {
    display: block;
    text-align: left;
}

.manage-contractor-22 {
    display: block;
    text-align: left;
}