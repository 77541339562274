.NotificationMessages {
    .filters {
        padding: 4px;
        display: flex;
        gap: 4px;
        width: 100%;
        border-radius: 4px;
        font-size: 0.7em;

        .filter.sm {
            width: 44px;
            max-width: 44px;
        }

        .filter {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
        }

        .filter .c {
            width: 100%;
            flex: 1;
        }

        .label {
            text-align: center;
        }
    }
}