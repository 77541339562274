.ChangePassword {
    .p-card-content {
        position: relative;
    }

    .p-card-content::before {
        content: "\e96a";
        font-family: 'primeicons';
        position: absolute;
        top: -105px;
        text-shadow: 0px 0px 3px #00000080;
        right: -54px;
        font-size: 6em;
        color: #f1f1f1;
        transform: rotate(-32deg);
    }
}