.ViewRequirementHistory {

    .opposite-content {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: flex-end;
    }

    .p-timeline-event-opposite {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 49%;
}

    .document-link {
        color: #333;
        text-decoration: none;
        border: 1px solid #eee;
        padding: 2px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 6px;
        background-color: #f2f2f2;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        .fas.fa-download {
            display: none;
        }
    }

    .document-link:hover {
        background-color: #eee;
        border: 1px solid #ddd;

        .fas.fa-download {
            color: #888;
            padding-right: 10px;
            display: block;
        }
    }

    .body {
        max-height: 70vh;
        overflow-y: auto;
    }

    .status-type-flag-2 {
        color: #bb0000;
    }

    .status-type-4 {
        color: #bb0000;
    }

    .danger {
        font-weight: bold;
        color: #bb0000;
    }

    .notes {
        color: #999;
        font-style: italic;
    }

    .requirement\.validation\.pending {
        background: orange;
    }

    .requirement\.validation\.validated {
        background: rgb(32, 150, 16);
        color: white;
    }

    .requirement\.validation\.rejected {
        background: rgb(230, 70, 52);
        color: white;
    }

    .requirement\.validation\.pending,
    .requirement\.validation\.validated,
    .requirement\.validation\.rejected {
        padding: 6px;
        width: 100%;
        text-align: center;
        font-size: 0.7em;
        border-radius: 6px;
    }
}

.g {
    max-width: 95%;
    min-width: 50%;
}

.historic {
    max-width: 90%;
    width: fit-content !important;
}
