.DocumentInfo {
    padding: 0px;
    font-size: 0.8em;
    height: 100%;
    display: flex;
    flex-direction: column;

    .g {
        background-color: #efefef;
        border-top: 1px solid #ddd;
    }

    .y {
        background-color: #f2f2f2;
    }

    .g.properties {
        flex: 1;
    }

    .properties {
        padding: 10px;
        width: 100%;

        strong {
            margin-bottom: 20px;
            color: #333;
        }

        label {
            padding-left: 10px;
        }

        strong {
            padding-top: 10px;
            text-align: center;
        }

        font-size: 0.8em;
        display: flex;
        flex-direction: column;

        .property {
            display: flex;
            flex-direction: row;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .property-name {
            font-weight: bold;
            margin-right: 5px;
        }
        .property-value {
            flex: 1;
            text-align: right;
            border-bottom: 1px solid #eee;
            color: #555;
            padding-right: 10px;
        }
    }
}