.EditRequirementTypeExpressions {
    .expressions-add-message {
        margin-top: 60px;
        padding: 20px;
        color: #aaa;
    }

    textarea {
        border-radius: 0px;
        background-color: #f2f2f2;
        border-color: #ddd;
        min-width: 300px;
        color: #777;
        font-size: 0.7em;
        font-family: Monaco, monospace;
    }

    select {
        border-radius: 0px;
        border-color: #ddd;
        color: #333;;
        max-width: 300px;
    }

    .expression-fragment {
        margin-bottom: 10px;
        border: 1px solid #ddd;

        td {
            padding-left: 6px;
            padding-right: 6px;
            border-right: 1px solid #eee;
        }

        .header {
            border-bottom: 1px solid #eee;
            padding-left: 10px;
            padding-top: 4px;
            padding-bottom: 4px;
            background-color: #f1f1f1;

            select {
                background: transparent;
                border: 0px solid;
                color: #777;
                font-style: italic;
            }
        }

        .footer {
            padding-left: 6px;

            select {
                border: 0px solid;
                background: transparent;
                border-bottom: 0px solid #ddd;
            }

            input {
                border-radius: 0px;
            }

            button:disabled {
                color: #ddd;
            }
        }
    }
}
