.JobHasWorkerSmallList {
    display: grid;
    grid-template-columns: 30% auto;

    .small-data-table {
        border-right: 1px solid #ccc;
        background-color: #eee;
    }
}

.job_worker_tooltip {
    background-color: white !important;
    border: 1px solid black !important;

    .p-tooltip-arrow {
        display: none !important;
    }

    .p-tooltip-text {
        background-color: white !important;
        color: black !important;
        font-size: small !important;
        box-shadow: none !important;
    }
}