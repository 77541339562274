.CommunicationForm {
    height: 100%;
    form {
        height: 100%;
    }

    .fields-grid {
        display: grid;
        padding: 20px;
        grid-template-columns: auto 20% auto 20% auto 20%;
        grid-gap: 5px;
        align-items: center;
    }

    .span-5 {
        grid-column: span 5;
    }

    .communication-form {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .description-files-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
    }
    .files-container {
        padding: 16px;
        display: flex;
        flex-direction: column;

        .title {
            color: #777;
            text-align: center;
            border-bottom: 1px solid #aaa;
        }
    }
    .file-row {
        color: #555;
        background-color: #eee;
        border-radius: 4px;
        padding: 16px;
        margin: 2px;
        display: flex;
        gap: 20px;
        i {
            cursor: pointer;
        }
    }
    .description-container {
        flex: 1;
        height: 100%;
        padding: 10px;

        .p-editor-container {
            height: 80%;
        }
    }

    .form-footer {
        display: flex;
        padding: 8px;
        flex-direction: row;
    }
}