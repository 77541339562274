@import "../../styles/colors.scss";

.notifications-messages-container {
    position: absolute;
    top: 96px;
    width: 800px;
    right: 20px;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;

    background: linear-gradient(129deg,
            $notifications-bg-color,
            lighten($notifications-bg-color, 6%),
            $notifications-bg-color);

    border-radius: 6px;
    border: 1px solid $notifications-bg-color-darker;
    padding: 4px;

    display: flex;
    flex-direction: column;
    z-index: 100000;

    gap: 5px;

    max-height: 60%;
    overflow: auto;

    .message-date {
        text-align: center;
        background-color: #e7be58;
        padding: 4px;
        border-radius: 10px;
        min-width: 140px;
    }

    .message.success {
        background-color: rgb(177, 245, 198);
        .close-handle {
            border-left: 1px solid rgb(147, 233, 154);
            color: green;
        }
    }
    .message.error {
        background-color: rgb(253, 200, 200);
        .close-handle {
            border-left: 1px solid rgb(233, 164, 147);
            color: rgb(85, 2, 2);
        }
    }
    .message.info {
        background-color: rgb(200, 232, 253);
        .close-handle {
            border-left: 1px solid rgb(147, 209, 233);
            color: rgb(2, 59, 85);
        }
    }

    .message.notification-type-3:hover {
        background: linear-gradient(129deg,
                #3f392d,
                lighten(#3f392d, 6%),
                #3f392d);
        box-shadow: rgba(100, 100, 111, 0.6) 0px 7px 29px 0px;
        border-radius: 3px;
        color: white;
        .close-handle {
            color: white !important;
        }
    }

    .message.notification-type-3 {
        background-color: transparent;
        color: black;
    }

    .message {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: antiquewhite;
        padding: 6px;
        border-radius: 4px;
        color: #555;
        align-items: baseline;
        font-size: 0.8em;
    }

    .message {
        .message-display-name {
            margin-top: 4px;
            padding-top: 4px;
            width: 100%;
            font-size: 0.8em;;
            border-top: 1px solid rgb(238, 189, 110);
        }
    }

    .message .body {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;

        .close-handle {
            cursor: pointer;
            border-left: 1px solid rgb(235, 203, 165);
            padding-left: 6px;
            color: rgb(148, 112, 65);
        }
    }
}