@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;800&display=swap');

.SelectWork {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    height: 100%;

    .works-title {
        color: #aaa;
        margin-bottom: 10px;
        font-weight: 100;
        font-size: 0.7em;
        display: flex !important;
        align-items: center !important;
        font-family: 'Fira Sans', sans-serif !important;
        font-size: 1.4em;
        font-weight: 1000;

        img {
            margin-left: 10px;
        }
    }

    .works {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        grid-gap: 50px;
        width: 100%;
    }
}

.work-card {
    height: 100%;
    width: 30%;
    cursor: pointer;
}

.card-select-work {
    height: 180px !important;
    width: 180px !important;
    box-shadow: 0px 0px 15px 4px #8d8a8a, 10px 15px 1px rgb(209, 104, 17);

    img {
        margin-top: 13px;
        position: relative;
        width: 100%;
        font-size: 2em;
        border-right: 1px;
    }

    .title {
        font-family: 'Fira Sans', sans-serif;
        font-weight: 1000;
        font-size: 0.9rem;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        gap: 25px;
        justify-content: center;
    }
}

.hover-work{
    width: fit-content;
    height: fit-content;
    :hover {
        img {
            filter: brightness(0.8);
            transform: scale(1.2);
        }
        span {
            transform: scale(1.2);
        }
    }
}

.work-19 {
    box-shadow: #00000087 10px 20px 30px 0px, #00000030 25px 13px 20px 7px, #2d517a -20px 30px 1px, #00000026 16px 17px 20px 0px inset !important;

    span {
        color: #2d517a;
        margin-top: 10px;
    }
}

.work-21 {
    box-shadow: #00000087 10px 20px 30px 0px, #00000030 20px 13px 20px 7px, #2f5501 -20px -30px 1px, #00000026 16px 17px 20px 0px inset !important;
    margin-top: 70px !important;

    span {
        color: #2f5501;
        margin-top: 23px;
    }
}

.work-22 {
    box-shadow: #00000087 -20px 30px 30px 0px, #00000030 -16px 14px 10px 0px, #d16811 20px 30px 1px, #00000026 16px 17px 20px 0px inset !important;

    span {
        color: #d16811;
        font-size: 1.2rem;
        margin-top: 20px;
    }
}

.work-23 {
    box-shadow: #00000087 10px 20px 30px 0px, #00000030 25px 13px 20px 7px, #2d517a -20px 30px 1px, #00000026 16px 17px 20px 0px inset !important;

    span {
        color: #2d517a;
        margin-top: 10px;
    }
}