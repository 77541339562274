.p-panel-header {
    padding: 8px !important;
    .p-panel-title {
        width: 100%;
    }
}

.yellow {
    .p-panel-content {
        background: #fffef7;
    }
    .p-panel-header {
        background-color: #fffce5;
    }
}

.p-tabview.h100p {
    .p-tabview-panels {
        height: 86%;
    }
}