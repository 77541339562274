.JobNotifyInformationLoaded {
  .message {
    padding: 16px;
    text-align: center;
    font-size: 0.8em;
    border-radius: 4px;
    border: 1px solid rgb(195, 228, 173);
    background-color: rgb(224, 245, 210);
  }
  .contractors-list {
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin: 20px;
    max-height: 20vh;
    overflow-y: auto;

    .contractor {
      font-size: 0.8em;
      padding: 6px;
      border-bottom: 1px solid #eee;
    }
  }
  .form {
    padding: 20px;
    label {
      font-size: 0.8em;
      color: #333;
    }
  }
}