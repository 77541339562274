.z-10002 {
    z-index: 10002 !important;
}

.p-dialog .p-dialog-footer .p-buttonset button {
    margin: 0 0 0 0 !important;
}

.p-dialog .footer {
    background-color: transparent;
    padding-top: 10px;
}

.p-dialog-content {
    padding: 0px !important;
}
.w80.p-dialog {
    width: 80vw;
}
.md.p-dialog {
    width: 50%;
}
.p-dialog.md {
    width: 50%;
}
.p-dialog.pd {
    padding: 0px;

    .p-dialog-content {
        padding: 2px !important;
    }
}
.p-dialog.table-bg {
    .p-dialog-content {
        background: #e1e1e1;
    }
}

.p-dialog.g {
    .p-dialog-content {
        background: #f2f2f2;
    }
}

.p-dialog {
    .footer {
        background-color: #eaeaea;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .footer.border {
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    .p-dialog-title {
        font-size: 0.9rem !important;
    }
    .p-dialog-header {
        padding: 0px;
        padding-bottom: 2px;
        padding-left: 10px;
        font-size: 1rem;
        background-color: #f3f3f3;
    }
    button.danger {
        background: #cc5252 !important;
        color: white;
    }
    a.button, button {
        border: 0px solid;
        padding: 6px;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        text-decoration: none;
        color: #333;
    }
    a.button.primary, button.primary {
        background-color: #dedede;
    }
    button:hover {
    }
}

.incidence_table_width{
    width: auto;
}
.modal-container {
    width: 100vw;
    height: 100vh;
    z-index: 1000000000000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);

    i, .loading-text {
        color: white;
    }
}

.p-dialog .rounded button, .p-dialog .rounded.footer button {
    border-radius: 12px !important;
}