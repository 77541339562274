@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;800&display=swap');

.WorkContainer {
    padding: 20px;

    .actions-title {
        color: #aaa;
        margin-bottom: 10px;
        font-weight: 100;
        font-size: 0.7em;
        display: flex !important;
        align-items: center !important;
        font-family: 'Fira Sans', sans-serif !important;
        font-size: 1.4em;
        font-weight: 1000;

        img {
            margin-left: 10px;
            width: 40px;
        }
    }

    .p-card {
        min-height: 150px;
        width: 150px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;

        .p-card-content {
            text-align: center;
            padding: 0 30px 0 30px;
        }
    }

    .Work {
        justify-content: center;
        display: flex;
        flex-direction: row;
        padding: 10px;

        .card-job {
            box-shadow: 0px 0px 15px 4px #8d8a8a, 2px 15px 1px #727e22;

            img {
                margin-top: 15px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: #727e22;
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-job-disabled {
            // box-shadow: 0px 0px 15px 4px #8d8a8a, 2px 15px 1px #727e22;
            background-color: #d4d4d4 !important;
            img {
                margin-top: 15px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: #727e22;
            }
        }

        .card-job-contractors {
            box-shadow: 0px 0px 15px 4px #8d8a8a, -8px 15px 1px #727e22;

            img {
                margin-top: 10px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: #727e22;
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-contractors-disabled {
            // box-shadow: 0px 0px 15px 4px #8d8a8a, -8px 15px 1px #727e22;
            background-color: #d4d4d4 !important;
            img {
                margin-top: 10px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: #727e22;
            }
        }

        .card-documents {
            box-shadow: 0px 0px 15px 4px #8d8a8a, 10px 15px 1px #b9901d;

            img {
                margin-top: 10px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: #b9901d;
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-documents-disabled {
            // box-shadow: 0px 0px 15px 4px #8d8a8a, 10px 15px 1px #b9901d;
            background-color: #d4d4d4 !important;
            img {
                margin-top: 10px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: #b9901d;
            }
        }

        .card-subc {
            box-shadow: 5px 2px 15px 4px #8d8a8a, -25px -3px 1px #30552c;

            img {
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 3px;
                gap: 25px;
                justify-content: center;
                color: #30552c;
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-subc-disabled {
            // box-shadow: 5px 2px 15px 4px #8d8a8a, -25px -3px 1px #30552c;
            background-color: #d4d4d4 !important;
            img {
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 3px;
                gap: 25px;
                justify-content: center;
                color: #30552c;
            }
        }

        .card-machinery {
            box-shadow: 5px 2px 15px 4px #8d8a8a, -20px -8px 1px rgb(114, 40, 17);

            img {
                margin-top: 10px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: rgb(114, 40, 17);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-access-control {
            min-height: 125px !important;
            width: 125px !important;
            box-shadow: 5px 2px 15px 4px #8d8a8a, -20px -8px 1px rgb(253, 147, 13);

            img {
                margin-top: 10px;
                position: relative;
                width: 250%;
                height: 250%;
                margin-left: -80%;
                display: block;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 3px;
                gap: 25px;
                justify-content: center;
                color: rgb(253, 147, 13);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-reports {
            min-height: 125px !important;
            width: 125px !important;
            box-shadow: 5px 2px 15px 4px #8d8a8a, -20px -8px 1px rgb(0, 90, 115);

            img {
                margin-top: 10px;
                position: relative;
                margin-left: -18%;
                width: 125%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: rgb(0, 90, 115);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-work {
            box-shadow: -5px -2px 15px 3px #8d8a8a, 5px 2px 15px 3px #8d8a8a;

            .client-img, .client-img-19, .client-img-20, .client-img-21, .client-img-22, .client-img-23, .client-img-24, .client-img-25, .client-img-26{
                margin-top: 55px;
                display: flex;
                width: 122px !important;
                height: 62px !important;

                img {
                    position: relative;
                    left: 23%;
                    width: 120%;
                    transform: translate(-50%, -50%);
                }
            }

            .client-img-27 {
                margin-top: 55px;
                display: flex;
                width: 95px !important;
                // height: 62px !important;
                margin-left: 10px;
                img {
                    position: relative;
                    left: 23%;
                    width: 120%;
                    transform: translate(-50%, -50%);
                }
            }

        }

        .card-contractors {
            box-shadow: -5px -2px 15px 4px #8d8a8a,
                20px 2px 1px rgb(45, 81, 122);

            img {
                margin-top: 5px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: rgb(45, 81, 122);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-download-area {
            min-height: 125px !important;
            width: 125px !important;
            box-shadow: 5px 2px 15px 4px #8d8a8a, -20px -8px 1px rgb(47, 85, 1);

            img {
                margin-top: 13px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: rgb(47, 85, 1);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-workers {
            box-shadow: -5px -2px 15px 4px #8d8a8a, 20px -8px 1px rgb(151, 78, 9);

            img {
                margin-top: 15px;
                position: relative;
                width: 75%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.9em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: rgb(151, 78, 9);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }

        .card-hse {
            box-shadow: 5px 2px 15px 4px #8d8a8a, -20px -8px 1px rgb(255, 3, 209);
            min-height: 125px !important;
            width: 125px !important;

            img {
                margin-top: 13px;
                position: relative;
                width: 100%;
                font-size: 2em;
                border-right: 1px;
            }

            .title {
                font-family: 'Fira Sans', sans-serif;
                font-weight: 1000;
                font-size: 0.8em;
                text-align: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;
                gap: 25px;
                justify-content: center;
                color: rgb(255, 3, 209);
            }

            :hover {
                img {
                    transform: scale(1.25);
                }
            }
        }
    }
}
