.constractorInvitation {

    width: 100%;

    label {
        width: 15% !important;
    }

    .inputgroup{
        width: 100%;
    }

    .BeginDate{
        div{
            width: 20% !important;
        }
        input{
            width: 20% !important;
        }
      }
}

.dateTime {

    div {
        width: 100% !important;
    }
    input {
        width: 100% !important;
    }
}