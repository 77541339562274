.toolbar-btn {
    background: linear-gradient(#f9f9f9,#ececec);
    border: 0;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.20), 0 1px 0 hsl(0%, 100%, 70%), inset 0 1px #fff;
    color: #555;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    padding: 3px 11px 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.tool-btn {
    cursor: pointer;
    border: 0px solid;
    i {
        margin-right: 10px;
    }
}
.tool-btn:hover {
    background-color: rgb(236, 236, 236);
    border-bottom: 1px solid rgb(105, 105, 105);
}

.small.p-chip {
    font-size: 0.8em;
}

.p-buttonset .p-button-outlined {
    border-right: 0px !important;
}
.p-fluid .p-button {
    width: unset !important;
}
.p-confirm-dialog-reject {
    background: #ccc;
}

.p-confirm-dialog-accept {
    background: rgb(212, 81, 81);
    border: 1px solid rgb(177, 46, 46);
    color: white;
    box-shadow: unset;
}

.p-buttonset button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-buttonset button:not(:last-child) {
    border-right: 0 none;
}
.p-buttonset button:last-child {
    border-top-left-radius: 0 0;
    border-bottom-left-radius: 0 0;
}
.p-buttonset {
    justify-content: stretch;
    align-content: stretch;
    justify-items: stretch;
    align-items: stretch;
    display: flex;
}

.p-splitbutton.flat-left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    .p-splitbutton-menubutton {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.p-splitbutton.dark {
    .p-splitbutton-menubutton:hover {
        background: #888 !important;
    }
    .p-splitbutton-menubutton {
        border-radius: 3px !important;
        background: #777;
        border-color: #666;
        color: #bbb;
    }
}

.flat.p-splitbutton {
    .p-button {
        border-radius: 0px !important;
    }
}

.p-splitbutton {
    .p-button:hover {
        background: #ebebeb !important;
    }
    .p-button-icon-only {
        width: 26px !important;
        min-width: 10px;
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

.toolbar {
    select {
        border-radius: 0px;
        border: 0px;
        background: transparent;
        color: #555;
        padding: 8px;
    }
}

button.y {
    background-color: #ffd05a;
    color: #815d06;
}

.breadcrumb-action-danger {
    background: #a70000 !important;
    border-radius: 4px !important;
}

.breadcrumb-action-green {
    background: #0f8f20 !important;
    border-radius: 4px !important;
    color: white !important;
}