.CommunicationsList {
    .view {
        margin: 10px;
        padding: 4px;
        background-color: #f2f2f2;
        flex: 1;
        border-radius: 4px;
        border: 1px solid #ddd;
        width: 100%;
        max-height: 80vh;
        overflow: auto;
    }
    .title {
        color: #888;
        border-bottom: 1px solid #ddd;
        text-align: center;
        margin-bottom: 4px;
    }
    .mg {
        margin: 10px;
    }
    .file-row {
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #eee;
        display: flex;
        flex-direction: row;
        padding: 10px;
        gap: 10px;

        span {
            flex: 1;
        }
    }
}


.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}