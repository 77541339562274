@import "./NavMenu.default.scss";
@import "./NavMenu.wide.scss";
@import "./NavMenu.dark.scss";
@import "../styles/colors.scss";

.worker {
    border-bottom: 2px solid #68b42a;
}

.contractor {
    border-bottom: 2px solid #ebba34;
}

.admin {
    border-bottom: 2px solid #bb0000;
}

.gestor{
    border-bottom: 2px solid #45AFD5;
}

.notifications-menu-item a {
    padding: 10px !important;
    padding-bottom: 3px !important;
}

.notifications-menu-item-open {
    background-color: $notifications-bg-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid $notifications-bg-color-darker;
    border-bottom: 0px;
    z-index: 1001;

    a, a:focus, a:hover {
        background-color: unset !important;
    }
}

#breadcrumb-right {
    display: flex;
    flex-direction: row-reverse;
    gap: 1em;
}