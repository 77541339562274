.user-form-container {
    display: grid;
    grid-template-columns: auto 50%;
    grid-gap: 10px;
}

.user-form-container .title {
    margin-bottom: 20px;
    border-bottom: 1px solid #999;
    display: block;
}

.user-form-container .footer {
    border-top: 1px solid #2196f3;
    grid-column: 1/3;
    padding-top: 10px;
}

.user-form-container .errors-container {
    grid-column: 1/3;
}