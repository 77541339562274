.help-desk-menu-button a {
    background: rgb(141, 214, 43) !important;
    background-color: rgb(141, 213, 43) !important;
    border-radius: 30px 8px 30px 30px !important;
    margin: 4px !important;
    padding: 7px !important;
}

.ql-formats button {
    min-width: unset !important;
}

.help-desk-menu-button.help-desk-true a {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.p-menu.sm.gray {
    background-color: #f4f4f4;
    ul {
        background-color: #f4f4f4;
        font-size: 0.8em;
    }
}

.overlay-min {
    .p-overlaypanel-content {
        padding: 2px !important;
    }
}

.p-overlaypanel-content {
    .footer {
        margin-top: 3px;
        background-color: white;
    }
}

a.link.light {
    color: #888;
}

a.link {
    color: #333;
    text-decoration: none;
}

.p-tag.warning {
    background: #dc8e3e;
}

.p-chip.primary {
    background-color: #43a4e8;
}

.flat {
    .p-accordion-header-link {
        border-radius: 0px !important;
    }
}

.p-badge.top-right {
    top: -10px;
    position: relative;
    left: -6px;
}

.p-badge.small {
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
}

.borderless {
    .p-accordion-header-link {
        border: 0px !important;
    }
}

.paginator-container.transparent {
    background-color: transparent;

    .search-filters .filter-btn {
        background: transparent;
        border: 0px solid;
        padding-top: 18px;
    }

    .p-paginator {
        background-color: transparent;

        button {
            background-color: transparent !important;
        }

        .p-dropdown {
            background-color: transparent;
            border: 0px solid;
        }
    }
}

.paginator-container {
    background-color: white;
    .exporter {
        align-self: center;
        i {
            color: #666;
        }
        padding-right: 20px;
    }
    .paginator {
        padding: 0px;

        .p-dropdown {
            height: unset !important;
        }
        .p-inputtext {
            padding: 0px;
        }

        button {
            border: 0px;
            display: unset !important;
            background: white !important;
        }
        .p-highlight {
            outline: 0 none;
            outline-offset: 0;
            border: 1px solid #ddd;
            background: #efefef !important;
            border-radius: 16px;
        }
    }
    .paginator-total-text {
        padding: 20px;
        font-size: 0.8em;
        color: #bbb;
    }
}

.footer {
    button {
        border: 0px solid !important;
        padding: 6px;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        border-radius: 0px !important;
        background: transparent !important;
    }
    button:hover {
        color: black !important;
    }
    button.primary {
        background-color: #dedede !important;
        color: black !important;
    }
    button.primary:disabled {
        background-color: #eee !important;
        color: #aaa !important;
    }
}

.p-tabview.no-padding {
    .p-tabview-panels {
        padding: 0px !important;
    }
}

.p-tabview.slim {
    .p-tabview-nav-link {
        font-size: 0.8em !important;
        padding: 6px !important;
        padding-left: 22px !important;
        padding-right: 22px !important;
    }
}

.p-tag.warn {
    background-color: #a76415;
}
.p-tag.error, .p-tag.danger {
    background-color: #b31c1c;
}
.p-tag.success {
    background-color: #0d6d15;
}
.p-tag.gr {
    background-color: #9b9b9b;
}
.p-tag.finalized {
    background-color: rgb(65, 62, 62);;
}
button.success {
    background: #32952e !important;
    color: white;
}
button.danger {
    background: #cc5252 !important;
    color: white;
}

.toolbar {
    .p-splitbutton {
        button {
            background: #eee;
            border: 0px;
            border-bottom: 1px solid #ddd;
        }
        button:hover {
            box-shadow: unset;
            color: black;
        }
    }
    button.tool, button[type=search], button[type=submit] {
        border: 0px solid;
        border-radius: 0px;
        background: #eee;
    }
    input {
        border: 0px solid !important;
        box-shadow: none !important;
    }
}

.p-carousel-indicator {
    button {
        border-radius: 10px !important;
        border: 0px solid rgb(202, 186, 186);
        width: 20px !important;
        height: 14px !important;
        display: block;
        padding: 0px !important;
        min-width: 20px !important;
    }
}

.p-menubar .p-submenu-list {
    z-index: 1050;
}


.flat-right {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.flat-left {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.flat-top {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.flat-bottom {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.no-bottom-border {
    border-bottom: 0px !important;
}

.no-top-border {
    border-top: 0px !important;
}

.p-confirm-dialog-message {
    display: block;
    padding: 20px;
    margin-left: 0px !important;
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
}

.requirements-title {
    padding: 10px;
    border-bottom: 1px solid #dcdcdc;
    background: #efefef;
    margin-bottom: 30px;
    position: relative;
    top: -1px;
}

.datatable-split-cell-container {
    width: 36px;
}

.br-r {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.sticky-0 {
    position: sticky;
    top: 0px;
}

label.light {
    color: #666;
    font-weight: lighter;
    font-size: 0.8em;
}

.p-splitbutton-menubutton.dark {
    background: #666;
    border: 1px solid #999;
    color: #333;
}
.p-splitbutton-menubutton.dark:hover {
    background: #333;
    color: #fff;
}

.datatable-split-btn {
    .p-splitbutton-defaultbutton {
        display: none;
    }
    .p-splitbutton-menubutton {
        border-color: #ddd;
        border-left: 1px solid #ddd;
        border-radius: 2px !important;
        gap: unset;
        grid-gap: unset;
        background: #eee;
        min-width: 26px !important;
        width: 26px !important;
    }
}

.tree-split-btn {
    .p-splitbutton-defaultbutton {
        border: none;
        display: none;
    }
    .p-splitbutton-menubutton {
        border: none;
        gap: unset;
        grid-gap: unset;
        background: transparent;
        min-width: 26px !important;
        width: 26px !important;
    }
}

.p-overlaypanel.no-padding {
    .p-overlaypanel-content {
        padding: 0px;
    }
}

.p-overlaypanel.sm.pd {
    .p-overlaypanel-content {
        padding: 4px;
    }
}

select.transparent {
    border: 0px;
    background-color: transparent;
}

.p-splitbutton.transparent {
    .p-splitbutton-menubutton {
        background: transparent;
        border: 0px;
    }
}

.flat {
    input, select {
        border-radius: 0px !important;
    }
}

.p-tag.g {
    background-color: #888;
}

.key-shortcut {
    display: flex;
    flex-direction: row;
    align-content: center;
    background: linear-gradient(181deg, #eee, transparent);
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border: 2px solid #e8e8e8;
    color: #b9b9b9;
    border-radius: 4px;
    font-size: 0.8em;
    align-items: center;
    box-shadow: inset 0px -1px 2px 0px #bdbdbd;
    margin: 2px;
}

.buttons {
    button {
        border: 0px solid #ddd;
        border-radius: 0px !important;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid #ddd;

        display: flex;
        flex-direction: row;
        .fas, .fa {
            align-self: flex-start;
        }
        .e {
            justify-content: center;
        }
    }
    button:hover {
        border-color: #bbb !important;
        background: #eaeaea;
    }
    button:first-child {
        border: 1px solid #ddd;
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
    }
    button:last-child {
        border: 1px solid #ddd;
        border-bottom-left-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        border-top: 0px;
    }
}

.panel-contractor {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #f8f9fa;

    .contractor-name {
        text-align: right;
        width: 100%;
        padding: 10px;
        color: #666;
        background-color: #f3f9ff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .title-box {
        padding: 8px;
        display: flex;
        gap: 10px;
        width: 100%;
        border-bottom: 1px solid #d2d2d2;

        .fas, .fa {
            color: #495057;
        }

        .title {
            text-align: right;
            color: #495057;
            flex: 1;
            justify-content: flex-end;
            border-bottom: 0px;
        }
    }
}

.p-overlaypanel-content {
    .search-filters {
        background: repeating-linear-gradient(
            42deg,
            #fff,
            #f2f2f2 1px,
            #f2f2f2 1px,
            white 100px
        );
    }
}

.search-filters {
    .error {
        font-size: 0.8em;
        padding: 10px;
        background-color: lightgoldenrodyellow;
        i {
            padding-right: 10px;
        }
    }
    .filters-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .footer {
        background-color: #eee;
        background: repeating-linear-gradient(
            42deg,
            #f2f2f2,
            #e9e9e9 1px,
            #eee 1px,
            #f2f2f2 100px
        );
    }

    .info {
        width: 30px;

        .p-button {
            padding: unset;
            height: unset;
        }
    }

    input[type=text] {
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 20px;
        border-radius: 4px;
        flex: 1;
    }
    select {
        flex: 1;
    }

    .filter-btn {
        align-self: center;
        height: 100%;
        padding-top: 10px;
        color: #717171;
        background: #e9ecef;
        border-left: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        border-top: 1px solid #cecece;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 0px;
        position: relative;
        left: 1px;
        border-top-right-radius: 0px !important;
        border-radius: 3px;
        border-bottom-right-radius: 0px !important;
    }

    label {
        width: 200px;
    }

    .footer {
        display: flex;
        flex-direction: row;
    }
}

.p-calendar-w-btn .p-datepicker-trigger {
    background: #ddd;
}

.p-datepicker {
    z-index: 10000000 !important;
}

.booltext-property, .bool-property {
    label {
        text-align: left !important;
        border: 0px solid !important;
        justify-content: unset !important;
    }

    .p-inputswitch .p-inputswitch-slider {
        min-width: 50px;
    }
}

.no-border .Properties {
    border: 0px solid;
}


.confortable td {
    padding: 10px !important;
}