@media only screen and (max-width: 900px) {
    .JobComponent {
        grid-auto-flow: dense;;
        grid-template-columns: auto !important;
    }
}

.JobComponent {
    display: grid;
    grid-template-columns: auto 300px;
    width: 100%;
    min-height: 70vh;

    #job-actions {
        button:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        button:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        button {
            border: 1px solid #e3e3e3;
            background: #eaeaea;
            border-radius: 0px;
        }
        button:hover {
            border: 1px solid #dadada;
            background: #e2e2e2;
        }
    }

    .job-section {
        button {
            margin-top: 4px;
            border: 1px solid #dac1a7;
            background: #f1e1da;
        }
        button:hover {
            border: 1px solid #cba683;
        }
    }

    .actions {
        border-left: 1px solid #ccc;
        background-color: #eee;
        padding-left: 10px;
        padding-right: 25px;
        padding-top: 10px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        .job-panel {
            .p-panel-header {
                background-color: #d4dca2;
                border: 1px solid #b6be81;
            }
            .p-panel-content {
                background: #f4f8dc;
                border: 1px solid #d8ddbd;
                border-top: 0px;
            }
        }

        .buttons .p-panel-content {
            display: flex;
            flex-direction: row;
            justify-content: right;
            gap: 6px;
            flex-wrap: wrap;
        }
    }
}

.azsa_job_add_worker{
    p{
        padding: 25px;
    }
}
