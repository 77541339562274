#help-desk-support {
    position: fixed;
    top: 96px;
    right: 100px;
    width: 420px;
    border-radius: 12px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.4);

    background-color: #efefef;
    padding: 20px;
    z-index: 1000000;

    .header {
        display: flex;

        .title {
            flex: 1;
            width: 100%;
        }
        text-align: center;
        color: white;
        font-size: 1.2em;
        padding: 20px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 200px;
        width: 100%;
        background-color: rgb(141, 213, 43);
        z-index: -1;
    }

    .content {
        max-height: 80vh;
        overflow-y: auto;

        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: white;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        span {
            color: #aaa;
            font-size: 0.8em;
        }
    }

    .files-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        border: 1px dashed #ccc;
        border-radius: 4px;
        padding: 20px;
        background-color: #f2f2f2;

        input {
            display: none;
        }
    }

    button {
        background-color: rgb(131, 214, 43);
        border: 1px solid #78b02b;
        border-radius: 8px;
        font-size: 1.2em;
        color: white;
    }

    button[disabled] {
        background-color: #ddd;
        border: 1px solid #ccc;
        color: #ccc;
    }

    .selected-files {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 1.2em;
            color: #777;
        }

        .list {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 10px;
            font-size: 0.8em;
            background-color: #f2f2f2;
        }

        .file {
            border-bottom: 1px solid #eee;
            padding-bottom: 6px;
            margin-bottom: 6px;
            color: #aaa;
            display: flex;
            flex-direction: row;
            align-items: center;


            .file-name {
                display: flex;
                flex-direction: column;
                width: 100%;
                flex: 1;
            }
        }
    }
}
