
.UploadRequirements {
  label {
    font-weight: bold;
    font-size: 0.8em;
  }
  .clarifications {
    font-size: 0.7em;
  }
  .file-area {
    background-color: #f4f4f4;
    border: 2px dotted #ccc;
    padding: 30px;
    border-radius: 4px;
    margin: 6px;
  }
  .req-help {
    gap: 20px;

    strong {
      font-size: 0.8em;
    }
    .section {
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      background-color: #ededed;
      padding: 10px;

      font-size: 0.7rem;
      gap: 10px;

      .title {
        margin-bottom: 6px;
        display: block;

        i {
          margin-right: 6px;
        }
      }

      a {
        text-decoration: none;
        color: #000;
      }
    }
  }

  .req-help {
    width: 300px;
  }
  
  .file-container {
    width: 50%;
  }
  .selected-files {
    border-top: 1px solid #888;
    text-align: right;
    font-size: 0.8em;
    color: #888;
  }
}