.ValidateAllRequirements {
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
        padding-top: 2px;
    }

    .DocumentInfo {
        padding: 0px;

        .properties {
            font-size: 0.8em;
            display: flex;
            flex-direction: column;

            .property {
                display: flex;
                flex-direction: column;
            }

            .property-name {
                font-weight: bold;
            }
            .property-value {
                text-align: right;
                padding-bottom: 6px;
                border-bottom: 1px solid #eee;
                color: #555;
            }
        }
    }

    .pending-requirements-title {
        padding: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        text-align: center;
        font-weight: normal;
        font-size: 0.8em;
        color: #aaa;
        background-color: #eee;
    }

    .pending-requirements-container {
        max-height: 50vh;
        overflow: auto;
    }

    .resource-name {
        font-size: 0.6em;
        color: #aaa;
        padding-left: 10px;
        clear: true;
        display: block;
        width: 100%;
        text-align: right;
    }

    .list {
        border-right: 1px solid #eee;
        background-color: #f7f7f7;
        width: 30%;
        max-width: 300px;
        height: calc(100vh - 30px);
        display: grid;
        grid-template-rows: 30px auto auto auto;
        grid-template-columns: 100%;
        .row {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            cursor: pointer;
        }
        .row:hover {
            background-color: #f2f2f2;
        }

        .row.rejected {
            color: #b37575;
        }
        .row.validated {
            background: #e8feed;
            color: #517e5f;
        }
        .row.selected {
            background-color: #ddd;
            border-left: 4px solid #bbb;
            font-weight: bold;
            border-top: 1px solid #aaa;
            border-bottom: 1px solid #aaa;
        }
    }

    .all-done {
        flex: 1;
        align-items: center;
        text-align: center;
        padding-top: 30%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        button {
            width: 100px;
        }
    }

    .title {
        height: 30px;
        text-align: center;
        background-color: #f3f3f3;
        border-bottom: 1px solid #ddd;
    }

    .requirement {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        position: relative;

        .body {
            flex: 1;
            max-height: calc(100vh - 60px);
            display: flex;
            flex-direction: column;
        }

        .document-viewer {
            width: 100%;
            height: 100%;
        }

        .footer {
            border-top: 1px solid #ddd;
        }
    }
}
