.work-form-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
}

.work-form-container .title {
    margin-bottom: 20px;
    border-bottom: 1px solid #999;
    display: block;
}

.work-form-container .footer {
    grid-column: 1/3;
}

.work-form-container .errors-container {
    grid-column: 1/3;
}