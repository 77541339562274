.view-requirement-validations {
    padding: 20px;
    border: 1px solid #aaa;
    position: absolute;
    min-width: 1000px;
    min-height: 100px;
    z-index: 2000000;
    top: 30px;
    left: -700px;
    background: #eee;
    border-radius: 6px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2),
                1px 1px 3px rgba(100, 100, 100, 0.9);

    .g-20 {
        border-radius: 4px;
    }

    .requirement\.validation\.pending,
    .requirement\.validation\.validated,
    .requirement\.validation\.rejected {
        padding: 10px;
        width: 100%;
        text-align: center;
    }

    .requirement\.validation\.pending {
        background: orange;
    }
    .requirement\.validation\.validated {
        background: rgb(32, 150, 16);
        color: white;
    }
    .requirement\.validation\.rejected {
        background: rgb(230, 70, 52);
        color: white;
    }

    .td-state {
        width: 200px;
    }
}
