.job-status {
  font-size: 0.7em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  min-width: 120px;
  cursor: pointer;
}
.job-status-1 {
  background-color: #2196F3 !important;
  border-color: #28465f !important;
  color: white !important;
}
.job-status-2 {
  background-color: #b93737 !important;
  border-color: #722323 !important;
  color: white !important;
}
.job-status-3 {
  background-color: lightgreen;
  border-color: darkseagreen;
  color: green;
}
.job-status-4 {
  background-color: rgb(65, 62, 62);
  border-color: rgba(45, 49, 45, 0.603);
  color: white;
}
.job-status-5 {
  background-color: #bb0000;
  border-color: #990000;
  color: white;
}
