.p-datatable-scrollable .p-datatable-tbody>tr>td {
}
.td-tree {
    width: 41px;
    text-align: center !important;
}
td.no-padding {
    padding: 0px !important;
}
.fit-content table, table.fit-content {
    height: fit-content;
    width: fit-content;
}

.td-sm {
    flex: 0 !important;
    min-width: 23px !important;
    text-align: center !important;
    width: 30px !important;
    max-width: 30px !important;
}

.td-indicator {
    width: 100px !important;
    text-align: center !important;
}
.td-datetime {
    width: 160px;
    text-align: center !important;
}
.td-datetime-sm {
    width: 100px;
    text-align: center !important;
}
.td-icon {
    width: 30px;
    text-align: center !important;
}
.td-avatar {
    width: 65px;
    text-align: center !important;
}
.td-md, .td-md-icon {
    width: 130px;
    text-align: center !important;
}
.td-lg {
    width: 200px;
    text-align: center !important;
}

.td-resources {
    width: 1250px;
}
.p-datatable.transparent tr td, .p-datatable.transparent table tr {
    border: 0px !important;
    background-color: transparent;
}
.p-datatable .p-datatable-thead > tr > th {
    background: #efefef;
}

.table-bg {
    background: #e3e3e3;
}

.table .p-datatable-header {
    padding: 0px !important;
}

.table-scroll-container {
    overflow-y: auto;
}

.table .table-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 2px;
}

.table table, .table {
    font-size: 13px;
    width: 100%;
}
.table td {
    border-right: 1px solid #dedede !important;
    vertical-align: middle !important;
}
.p-datatable-thead th {
    padding: 4px !important;
    text-align: center !important;
}
.p-datatable-tbody td {
    padding: 4px !important;
}
.p-datatable-tbody td.no-padding {
    padding: 0px !important;
    height: 100%;
}
.table .table-toolbar button {
    border-radius: 0px;
    border: 0px solid;
    box-shadow: none;
    padding: 6px;
    color: #555;
    background-color: transparent;
    background: transparent;
}
.table .table-toolbar button:hover {
    background-color: #eee;
}

.table .table-toolbar button i {
    margin-right: 6px;
}

.table tr.hover:hover td {
    background-color: aliceblue;
}

table.table.bordered tr td {
    border-bottom: 1px solid #ccc;
}

table.table.bordered th {
    border-bottom: 1px solid #afafaf;
}
table.table th.header {
    background-color: #e9e9e9;
}
.fixed-header-t36 thead {
    position: sticky;
    top: 36px;
    z-index: 1000;
}

.table.lg td {
    padding: 12px;
}

.table.confort td {
    padding: 5px;
}

.p-column-header-content >
    .p-column-title {
        flex: 1;
    }

.p-datatable .p-sortable-column-icon {
    color: #ccc !important;
}

.p-column-header-content {
    width: 100%;
}

.fixed-0 {
    position: sticky;
    top: 1px;
}
