.admin-module-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px;
    gap: 50px;

    .p-card {
        width: 30%;
    }

    .logs-card {
        width: 50%;
    }

    .metrics-card {
        width: 40%;
    }
}