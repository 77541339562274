.default-theme {
    #home .bottom {
        margin-top: 10px;
        padding: 60px;
        color: white;
        border-radius: 6px;
        // background: linear-gradient(90deg, #333 0%, #666 50%, #333 100%);
    }

    #home .bottom img {
        width: 400px;
        margin-bottom: 40px;
    }
}

.wide-theme {
    #home {
        max-width: 65%;
        height: 100%;
        margin: auto;
        padding-top: 10px !important;
        justify-content: center;
        display: grid;
       // grid-template-rows: 3fr 1fr 1fr;
    }

    #home .top{
        display: flex !important;
        flex-direction: row;
        width: 100% !important;
        justify-content: center !important;
        padding-top: 5%;
    }

    #home img{
        margin-left: 61px;
        width: 30%;
     }

    #home .middle {
        align-items: center !important;
        justify-content: center !important;
        font-size: 1.2em;
        color: grey;
        text-align:  center;
        display: flex;
    }

    #home .middle div
    {
        width: 100% !important;
    }

    #home .middle img
    {
        width: 7%;
        justify-content: center !important;
    }
    #home .bottom {
        align-items: center !important;
        justify-content: center !important;
        margin-top: 10px !important;
        margin-bottom: 0 !important;
        padding: 0px !important;
        color: white;
        border-radius: 6px;
        display: flex;
        // background: linear-gradient(90deg, #333 0%, #666 50%, #333 100%);
        align-items: end !important;
    }

    #home .bottom img {
      //  width: 75%;
     //   margin-bottom: 0px !important;
    }
}
#home {
  //  display: flex;
  //  flex-direction: column;
}

.clip {
    overflow-y: clip;
    background-color: #f7f7f7;
    .debug, .release {
        height: 100vh;
        display: grid;
        grid-template-rows: 13vh 70vh 17vh;

        #main-menu {
         //   display: grid;
         //   grid-template-rows: 58% 42%;
         display: flex;
         flex-direction: column;
            .logos{
           //     grid-row: 1/3;
           //     grid-column: 1/3;
                height: auto;
                .gstore{
                    height: auto;
                    width: auto;
                }
                span{
                    height: auto;
                    width: auto;
                    background-color: white;
                }
                .ils{
                    height: auto;
                    width: auto;
                }
            }
            .header-menu{
              //  grid-row: 3/4;
              //  grid-column: 1/3;
                height: auto;
                margin-top: 0;
                position: relative;
                div{
                    a{

                    }
                    ul{
                        li:first-child{

                        }
                        li:nth-child(2){

                        }
                        li:nth-child(3){

                        }
                    }
                }
            }
            .p-toast{



            }
        }

        #container {
            margin: 0px;

            #home{
                display:grid;
                grid-template-rows: 50% 10% 40%;
                .top{
                    padding-top: 5%;
                    img{
                        width: auto;
                    }
                    .login-content{
                        div{
                            width: auto;
                        }
                    }
                }
                .middle{

                    span{

                    }
                    img{
                        height: auto;
                        margin-left: 3px;
                    }
                }
                .bottom{
                    height: 100%;
                    img{
                        width: 100%;

                    }
                }
            }
        }

        .footer-container {

            #footer{
                z-index: 0;
                .left{

                }
                .info{

                }
                .certs{

                }
            }
        }
    }
}

@media (max-width: 600px){
    .middle {
        display: none !important;
    }
}

@media (min-width: 2100px)  {

    .top{
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .middle{
        padding-top: 0%;
        grid-column: 2/3;
        grid-row: 1/2;
    }

    .bottom{
        grid-column: 1/3;
        grid-row: 3/3;
        width: 120%;
        img{
            margin-left: 0 !important;
            height: 100%;
        }



    }

    .clip{
    }

}



@media screen and (max-height: 780px ) and (min-width: 1200px){

    .clip {
    }
    .top{
        grid-column: 1/2;
        grid-row: 1;
    }

    .middle {
        width: 135%;
        margin-left: 5%;
        grid-column: 2/3;
        grid-row: 1;
        img{
            width: auto !important;
            margin-left: 0 !important;
        }
        span{

        }
    }

    .bottom{
        grid-column: 1/3;
        grid-row: 3;
        img {
            height: 100%;
        }
    }
}



@media screen and (max-height: 780px ) and (max-width: 1200px){

    .clip {
    }
    .top{
        grid-column: 1/2;
        grid-row: 1;
    }

    .middle {
     display: none !important;
     //grid-column: 2/3;
     //grid-row: 1;
        img {
            width: auto !important;
            margin-left: 0 !important;
        }
    }

    .bottom{
        grid-column: 1/3;
        grid-row: 3;
       // min-width: 10%;
       // max-width: 70%;
      //  position: relative;
      //  left: 32%;
      img{
       // height: 100%;
      }
    }
}

