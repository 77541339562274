.JobPanelActions {
  border-left: 1px solid #ddd;
  background-color: white;
  font-size: 0.8em;

  padding: 20px;

  display: flex;
  flex-direction: column;

  .p-panel-header {
    background-color: unset !important;
    border: 0px !important;
  }
  .p-panel-content {
    background-color: unset !important;
    border: 0px !important;
  }

  button {
    border-radius: 0px;
    background: transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    .fas, .fa {
      align-self: flex-start;
    }
    .e {
      justify-content: center;
    }
    small {
        clear: both;
        display: block;
        font-style: italic;
        margin-top: 5px;
    }
  }

  button.download {
    border: 1px solid #d9f06b;
    background: #e0f481;
  }

  .section-subcontext {
    text-align: center;
    padding: 4px;
    margin: 4px;
  }

  .job-section {
    .action {
      margin-top: 20px;
    }
    margin-bottom: 20px;
  }

  .sub-section {
    background: #f7f7f7;
    border-radius: 6px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4), 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  }

  .section-title {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    font-size: 0.8em;
    color: #555;
    text-shadow: 0px 0px 3px white, 0px 0px 4px rgba(0, 0, 0, 0.2);
  }

  .section {
    color: #555;
    .body {
      padding: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0px;
      grid-gap: 0px;

      button {
        border: 0px solid #ddd;
        border-radius: 0px;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid #ddd;
      }
      button:first-child {
        border: 1px solid #ddd;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      button:last-child {
        border: 1px solid #ddd;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 0px;
      }
      button.g {
        border-color: #bcd9b6 !important;
        background: #e5ffe0 !important;
      }
      button.g:hover {
        background: #d5f6ce !important;
      }
      button.b {
        border-color: #c1dae7 !important;
        background: #e0f1ff !important;
      }
      button.b:hover {
        background: #d6eafa !important;
      }
      button.o {
        border-color: #ead7b9 !important;
        background: #fff0e0 !important;
      }
      button.o:hover {
        background: #f5e2cf !important;
      }
    }
    .title {
      border-bottom: 1px solid #ddd;
      i {
        font-size: 1.2em;
        margin-right: 4px;
      }
    }
  }

  .action {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    color: #555;

    i {
      font-size: 1.2em;
      margin-right: 4px;
    }
  }
}
