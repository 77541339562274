.restrict-worker-dialog {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 10px;

    .restriction-remarks,
    .restriction-reason {
        min-height: 50px;
        height: auto;
    }

    textarea {
        resize: vertical;
    }

    .inputgroup {
        label {
            width: 200px;
        }
    }
}