.ViewRequirementDocument {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 10000;

    .PdfViewer .canvas-container {
        max-height: 100vh;
        height: 100vh;
    }

    .PdfViewer .toolbar .button-group {
        display: flex;
        padding: 10px;

        button {
            min-width: 20px;
        }
    }
}