.footer-container {
    width: 100%;
    /* background-color: #99C21E !important; */
    color: white;
    text-align: center;
    margin-top: 0px;
}
#footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: white;
    background: #555;
}
/* #footer .desc img {
    padding-top: 10%;
    width: 190px;
} */

#footer.reset-a {
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: auto;
}

#footer .left {
    width:  30%;
    /* margin: 0px 20px 0px 0px !important; */
    text-align: right !important;
}

#footer .info {
    width: 40% !important;
    margin-top: 20px;
}

#footer .info .imgs {
    display: flex;
    width: 100%;
    gap: 25px;
    justify-content: center !important;
    margin-top: 20px !important;
}

#footer .info .link {
    /* width: 250px;
    height: 100px; */
    border-radius: 2px;
    /* box-shadow: inset 0 0 10px black; */
    /* background-color: #99C21E; */
    font-size: 0.85em !important;;
    /* margin-top: 35px; */
}

#footer .info .link .linkedin {
    /* margin: 10px 0px 10px 0px !important;
    width: 30%; */
    width: 30px;
    box-sizing: unset !important;
}

#footer .info .link .phone {
    /* margin: 10px 0px 10px 0px !important;
    width: 40%; */
    width: 39px;
    box-sizing: unset !important;
}

#footer .info .link .web {
    /* margin: 10px 0px 10px 0px !important;
    width: 30%; */
    width: 30px;
    box-sizing: unset !important;
}

#footer .info .link .mail {
    /* margin: 10px 0px 10px 0px !important;
    width: 30%; */
    width: 30px;
    box-sizing: unset !important;
}


#footer .certs {
    margin-left: 20px;
    display: flex;
    width: 30% !important;
    margin-top: 25px;
}


#footer .certs .cert {
    /* margin-top: 10%; */
    width: 100px !important;
    height: 100px !important;
}

/* #footer .desc {
    width: 30% !important;
    color: #838383;
    font-size: 80%;
    text-align: justify;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    justify-content: center !important;
} */

.footer-container .copyright {
    margin-top: 10px;
    font-size: 10px;
    color: #888;
}
/* #sello_prodat img {
    width: 50px !important;
    height: 57px !important;
} */

#footer .contact-container {
    color: white;
    display: grid;
    grid-template-columns: 30% auto;
    grid-template-rows: 30% 30% 30%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    font-size: 1.3em;
    justify-items: left;
    align-items: center;
}
#footer .contact-container a {
    color: white;
}

.footer-container {
    width: 100%;
    /* background-color: #99C21E !important; */
    color: white;
    text-align: center;
    margin-top: 0px;
}

.link{
    text-align: center;
}
#footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: white;
    background: #555;
    font-family: sans-serif;
}
/* #footer .desc img {
    padding-top: 10%;
    width: 190px;
} */

#footer.reset-a {
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: auto;
}

#footer .left {
    width:  30%;
    /* margin: 0px 20px 0px 0px !important; */
    text-align: right !important;
}

#footer .info {
    width: 40% !important;
    margin-top: 20px;
}

#footer .info .imgs {
    display: flex;
    width: 100%;
    gap: 25px;
    justify-content: center !important;
    margin-top: 20px !important;
}

#footer .info .link {
    /* width: 250px;
    height: 100px; */
    border-radius: 2px;
    /* box-shadow: inset 0 0 10px black; */
    /* background-color: #99C21E; */
    font-size: 0.85em !important;
    /* margin-top: 35px; */
}

#footer .info .link .linkedin {
    /* margin: 10px 0px 10px 0px !important;
    width: 30%; */
    width: 30px;
    box-sizing: unset !important;
    padding-bottom: 3px;
    font-size: 0.85em !important;
}

#footer .info .link .phone {
    /* margin: 10px 0px 10px 0px !important;
    width: 40%; */
    width: 39px;
    box-sizing: unset !important;
    padding-bottom: 3px;
    font-size: 0.85em !important;
}

#footer .info .link .web {
    /* margin: 10px 0px 10px 0px !important;
    width: 30%; */
    width: 30px;
    box-sizing: unset !important;
    padding-bottom: 3px;
    font-size: 0.85em !important;
}

#footer .info .link .mail {
    /* margin: 10px 0px 10px 0px !important;
    width: 30%; */
    width: 30px;
    box-sizing: unset !important;
    padding-bottom: 3px;
    font-size: 0.85em !important;
}


#footer .certs {
    margin-left: 20px;
    display: flex;
    width: 30% !important;
    margin-top: 25px;
    font-size: 10px !important;
    font-size: 0.85em !important;
}


#footer .certs .cert {
    /* margin-top: 10%; */
    width: 100px !important;
    height: 100px !important;
}

/* #footer .desc {
    width: 30% !important;
    color: #838383;
    font-size: 80%;
    text-align: justify;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    justify-content: center !important;
} */

.copyright {
    margin-top: 10px;
    font-size: 10px !important;
    text-align: center;
}
/* #sello_prodat img {
    width: 50px !important;
    height: 57px !important;
} */

#footer .contact-container {
    color: white;
    display: grid;
    grid-template-columns: 30% auto;
    grid-template-rows: 30% 30% 30%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    font-size: 1.3em;
    justify-items: left;
    align-items: center;
}
#footer .contact-container a {
    color: white;
}